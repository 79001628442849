import Style from "./ServicesLocalization.module.css";
import { ServiceLayout } from "../../Components/ServiceLayout";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import HomeCardItem from "../../Components/HomeCardItem/HomeCardItem";
import { useEffect } from "react";
import ReactGA from 'react-ga4';

export const ServicesLocalization = () => {
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/service/localization" });
    ReactGA.event({
      category: "ServiceCategory",
      action: "View Localization",
      label: "Localization", // optional
      value: 0, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    document.title = "iMachinary - Localization"
  }, [])

  return (
    <div className={Style.InnerContainer}>
      <ServiceLayout
        buttonText={t("Go Local")}
        title={`${t("Home").toUpperCase()} / ${t("Services").toUpperCase()} / ${t(
          "Precise Localization"
        ).toUpperCase()}`}
        subtitle={t("Precise Localization")}
        handleClick={() => {
          history.push("/process", { services: ["Localization"] });
        }}
      >
        <div className={Style.TwoColContainer}>
          <div className={Style.GoingGlobalContainer}>
            <span className={Style.GoingGlobalTag}>
              {t("“Transformations For The Global Scale.”")}
            </span>
            <span className={Style.GoingGlobalText}>
              {t(
                "No matter where your business began, the broad range of online platforms have made serving customers on a global scale more accessible than ever before."
              )}
            </span>
            <span className={Style.GoingGlobalText}>
              {t(
                "However, that doesn’t mean launching international projects is always smooth sailing."
              )}
            </span>
            <span
              className={Style.GoingGlobalText}
              dangerouslySetInnerHTML={{
                __html: t(
                  "At iMachinary, we’re here to take the stress and endless hours of labor out of building localized platforms, so you can invest more of your priceless time and hard earned money back where it belongs… your business."
                ),
              }}
            ></span>
          </div>
          <div className={Style.ImgContainer}>
            {" "}
            <img
              src={'/images/services/LocalizationImage.png'}
              alt="LocalizationImage"
              width="390"
              height="212"
            ></img>{" "}
          </div>
        </div>
        <div className={Style.BlueBackgroundSection}>
          <span className={Style.BlueBackgroundSectionTitle}>
            {t("Imagine, Your Next Project Adapted With…")}
          </span>
          <ul>
            <li className={Style.BlueBackgroundSectionItem}>
              {t(
                "Streamlined processes that empower you to save time, money and unnecessary hassles"
              )}
            </li>
            <li className={Style.BlueBackgroundSectionItem400}>
              {t(
                "Personalized translation support that works directly with you to reimagine your existing content for a global audience"
              )}
            </li>
            <li className={Style.BlueBackgroundSectionItem}>
              {t(
                "In-depth research designed to paint a vivid picture of your audience needs, so you can strategize accordingly"
              )}
            </li>
            <li className={Style.BlueBackgroundSectionItem400}>
              {t(
                "On-going analysis that allows you to reconfigure your project at a moment’s notice"
              )}
            </li>
          </ul>
          <span className={Style.BlueBackgroundSectionFootLine}>
            {t(
              "…and the expertise of a diverse team of linguists, marketing experts and IT professionals standing by to serve you."
            )}
          </span>
        </div>
        <span
          className={Style.YourVision}
          dangerouslySetInnerHTML={{
            __html: t("Make It Local"),
          }}
        ></span>
        {/* <span className={Style.OurResources}>{t("In Just 3 Steps")}</span> */}
        <div className={Style.cardsContainer}>
          <HomeCardItem
            // image={LocalizationImage}
            title={t("Strategize")}
            content={t(
              "Whether you’re customizing an existing product or launching a new international undertaking, we work to ensure that your brand resonates with each and every global market you enter."
            )}
          />
          <HomeCardItem
            // image={LocalizationImage}
            title={t("Conceptualize")}
            content={t(
              "Our team of leading linguists, project managers, graphic designers and software developers are here to bring your vision to life and keep your content relevant in international markets."
            )}
          />
          <HomeCardItem
            // image={LocalizationImage}
            title={t("Translate")}
            content={t(
              "We keep your messages fresh and relevant by assigning you a personal translation consultant who works with you to create powerful messages and enhance the relatability of all existing copy."
            )}
          />
        </div>
        <div className={Style.FooterContainer}>
          <span className={Style.AllYourInternationalization}>
            {t("Localized solutions, any time and any place.")}
          </span>
        </div>
      </ServiceLayout>
    </div>
  );
};
