import HomeEntryItem from "../../Components/HomeEntryItem/HomeEntryItem";
import Style from "./Home.module.css";
import { HomeHeader } from "./Components/HomeHeader";
import { Container } from "../../Components/Container/Container";
import HomeCardItem from "../../Components/HomeCardItem/HomeCardItem";
import { useTranslation } from "react-i18next";
import { HomeBlueSection } from "./Components/HomeBlueSection";
import { HomePreFooter } from "./Components/HomePreFooter";
import { useEffect } from "react";
import ReactGA from 'react-ga4';

// const { i18n } = useTranslation();

export const Home = () => {
  const { t } = useTranslation();

  useEffect(() => {
    // ReactGA.initialize("G-VCFXV8D999");
    ReactGA.send({ hitType: "pageview", page: "/home" });
    ReactGA.event({
      category: "Home",
      action: "View Home",
      label: "Home", // optional
      value: 0, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    // ReactGA.event({
    //   category: "Language Selected",
    //   action: "Language: "+i18n.language,
    //   label: i18n.language, // optional
    //   value: 0, // optional, must be a number
    //   nonInteraction: true, // optional, true/false
    //   transport: "xhr", // optional, beacon/xhr/image
    // });
    document.title = "iMachinary - Home"
  }, [])

  return (
    <>
      <HomeHeader />
      <Container
        style={{
          backgroundColor: "#f5f5f7",
          paddingTop: "5%",
          paddingBottom: "6%",
        }}
      >
        <div className={Style.expandTitle}>
          {t("Expand Your Operation With…")}
        </div>
        <HomeEntryItem
          title={t("Seamless Internationalization")}
          image={'/images/home/Internationalization.png'}
          content={t(
            "No matter what the scope of your operation looks like, our team is here to make going global swift, simple and stress-free. We offer industry leading translation services, combined with cutting edge technology to transform your business message into a multilingual international presence."
          )}
        />
        <HomeEntryItem
          title={t("Enhanced Localization")}
          image={'/images/home/Internationalization.png'}
          content={t("Booming businesses are built on a rock solid foundation. That’s why we work closely with your team to strategize and execute winning localization protocols for projects of all magnitudes, so you can spend less time stressing the little stuff and more time growing your business.")}
        />
      </Container>
      <HomeBlueSection />
      <Container
        style={{
          backgroundColor: "#f5f5f7",
          paddingTop: "5%",
          paddingBottom: "4%",
        }}
      >
        <div className={Style.cardsSectionContainer}>
          <div className={Style.expandTitlev2}>
            {t("Our Team Delivers Exceptional Results Using…")}
          </div>
          <div className={Style.cardsContainer}>
            <HomeCardItem
              // image={ImageSliderInternationalization}
              title={t("Leading Technology")}
              content={t("We utilize modern IT solutions to build streamlined internationalization and localization systems custom tailored to your brand, featuring language translation, website creation and every facet in between.")}
            />
            <HomeCardItem
              // image={ImageSliderInternationalization}
              title={t("Personal Care")}
              content={t("Our team is your team. As a client, we’ll assign you a dedicated translator who works directly with you to cover all of your international bases and ensure each project is translated with 100% accuracy, every single time.")}
            />
            <HomeCardItem
              // image={ImageSliderInternationalization}
              title={t("24/7 Support")}
              content={t("We know that running a business, locally or internationally, doesn’t end after office hours. So, we keep our highly skilled customer service team available to you at a moment’s notice, day or night.")}
            />
          </div>
        </div>
      </Container>
      <HomePreFooter/>
    </>
  );
};
