import { Link } from "react-router-dom";
import { IoMenu, IoClose } from "react-icons/io5";

import logoLight from "./img/logo-light.png";
import logoDark from "./img/logo-dark.png";

import styles from "./NavBar.module.scss";
import { NavBarButton } from "../NavBarButton";
import { Button } from "../Button";
import { LanguageSelector } from "../LanguageSelector";
import NavBarContext from "../../Shared/NavBarContext";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import ViewportContext from "../../Shared/ViewportContext";
import { useContext } from "react";
import { IconButton } from "../IconButton";
import { FaGlobeAmericas } from "react-icons/fa";

const NavBar = ({ theme, children, onStartNowClick }) => {
  const MyWidth = useContext(ViewportContext).myWidth;
  useEffect(() => {
    if (MyWidth > 1024) {
      setIsMenuOpen(false);
    }
  }, [MyWidth]);

  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "initial";
    }
  }, [isMenuOpen]);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <NavBarContext.Provider value={{ theme, isMobile: MyWidth < 1024 }}>
      <div className={`${styles.navbar} ${styles[`navbar-${theme}`]}`}>
        <button
          className={styles["navbar-hamburger"]}
          type="button"
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
            setIsLangMenuOpen(false);
          }}
        >
          {isMenuOpen ? <IoClose /> : <IoMenu />}
        </button>
        <Link to="/" className={styles.logo}>
          <img src={theme === "dark" ? logoLight : logoDark} />
        </Link>
        {MyWidth < 1024 && (
          <IconButton
            onClick={() => {
              setIsLangMenuOpen(!isLangMenuOpen);
              setIsMenuOpen(false);
            }}
            icon={FaGlobeAmericas}
            variant={theme !== "dark" ? "blue" : "default"}
            style={{ background: "transparent", marginRight: "2%" }}
          />
        )}
        <div
          className={`${styles["navbar-main"]} ${
            isMenuOpen ? "" : styles["navbar-main-collapsed"]
          }`}
        >
          {(isMenuOpen || MyWidth > 1024) && (
            <div className={styles.links}>{children}</div>
          )}
          <div className={styles.language}>
            <LanguageSelector
              MyWidth={MyWidth}
              OpenedFromMobile={isLangMenuOpen}
              setIsLangMenuOpen={setIsLangMenuOpen}
            />
            {MyWidth > 1024 && (
              <NavBarButton text={t("Contact us")} href="/contact-us" />
            )}
          </div>
          {MyWidth > 1024 && (
            <Button
              style={{
                fontSize: "1.055rem",
                fontWeight: "var(--medium)",
                padding: "6px 14px",
                flex: "1 0 auto",
                borderRadius: '13px',
                minHeight: 0
              }}
              onClick={onStartNowClick}
            >
              {t("Start now")}
            </Button>
          )}
        </div>
      </div>
    </NavBarContext.Provider>
  );
};

NavBar.defaultProps = {
  theme: "dark",
  onStartNowClick: () => {},
};

export { NavBar };
