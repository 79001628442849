import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import NavBarContext from "../../Shared/NavBarContext";
import { ReactComponent as DownArrow } from "./img/atom-icon-field-arrow-header-down.svg";
import { ReactComponent as UpArrow } from "./img/atom-icon-field-arrow-header-up.svg";

import styles from "./NavBarButton.module.scss";

const NavBarButton = ({ text, children, href, onClick, t, IsNotBold }) => {
  const { theme, isMobile } = useContext(NavBarContext);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const { i18n } = useTranslation();

  return (
    <div
      className={`${styles["navbar-btn"]}  ${styles[`navbar-btn-${theme}`]}`}
      onMouseEnter={() => {
        if (!isMobile) setIsCollapseOpen(true);
      }}
      onMouseLeave={() => {
        if (!isMobile) setIsCollapseOpen(false);
      }}
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          setIsCollapseOpen(!isCollapseOpen);
        }
      }}
    >
      <div className={styles.main}>
        {href && !onClick ? (
          <Link
            to={href}
            className={IsNotBold ? styles.langNotPicked : (i18n.language === 'ko' || i18n.language === 'ja' ? styles.buttonStrong : styles.button) }
          >
            {text}
          </Link>
        ) : (
          <span
            className={`${isCollapseOpen ? styles.underlined : ""} ${
              IsNotBold ? styles.langNotPicked : (i18n.language === 'ko' || i18n.language === 'ja' ? styles.buttonStrong : styles.button)
            } `}
          >
            {t ? t(text) : text}
          </span>
        )}
        {children && isMobile && (
          <div
            className={styles.arrow}
            onClick={() => setIsCollapseOpen(!isCollapseOpen)}
          >
            {isCollapseOpen ? <UpArrow /> : <DownArrow />}
          </div>
        )}
      </div>
      {children && isCollapseOpen && (
        <div
          className={`${styles["navbar-btn-collapse"]} ${
            isCollapseOpen ? styles["navbar-btn-collapse-open"] : ""
          } ${styles[`navbar-btn-collapse-${i18n.language}`]}`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

NavBarButton.defaultProps = {
  onClick: null,
  href: null,
};

export { NavBarButton };
