import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resources from "../i18n/translation.json";

const langList = [
  "en",
  "es",
  "pt",
  "de",
  "nl",
  "it",
  "fr",
  "ja",
  "ko",
  "pl",
  "ru",
  "sv",
  "zh",
  "en_GB",
  "es_ES",
  "pt_PT",
  "ar",
];

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    resources,
    interpolation: { escapeValue: false },
    detection: {
      order: ["sessionStorage", "navigator"],
      caches: ["sessionStorage"],
    },
  });

if (!langList.includes(i18n.language)) {
  const newLang = i18n.language.replaceAll("_", "-");
  if (langList.includes(newLang.split("-")[0])) {
    i18n.changeLanguage(newLang.split("-")[0]);
  } else {
    i18n.changeLanguage("en");
  }
}

document.documentElement.setAttribute("lang", i18n.language);
document.documentElement.setAttribute("dir", i18n.dir());

i18n.on("languageChanged", (lang) => {
  document.documentElement.setAttribute("lang", lang);
  document.documentElement.setAttribute("dir", i18n.dir());
});

export default i18n;
