import Style from "./ServicesDTP.module.css";
import { ServiceLayout } from "../../Components/ServiceLayout";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import HomeCardItem from "../../Components/HomeCardItem/HomeCardItem";
import { useEffect } from "react";
import ReactGA from 'react-ga4';


export const ServicesDTP = () => {
  // const MyWidth = useContext(ViewportContext).myWidth;
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/service/dtp"});
    ReactGA.event({
      category: "ServiceCategory",
      action: "View DTP",
      label: "DTP", // optional
      value: 0, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    document.title = "iMachinary - DTP"
  }, [])


  return (
    <div className={Style.InnerContainer}>
      <ServiceLayout
        buttonText={t("Get It Published")}
        title={`${t("Home").toUpperCase()} / ${t("Services").toUpperCase()} / ${t(
          "Simplified Desktop Publishing"
        ).toUpperCase()}`}
        subtitle={t("Simplified Desktop Publishing")}
        handleClick={() => {
          history.push("/process", { services: ["Desktop Publishing"] });
        }}
      >
        <div className={Style.TwoColContainer}>
          <div className={Style.GoingGlobalContainer}>
            <span className={Style.GoingGlobalTag}>
              {t("“Get It Published, Get Back To Work.”")}
            </span>
            <span className={Style.GoingGlobalText}>
              {t(
                "At iMachinary, we believe that expanding globally shouldn’t have to mean starting over from scratch."
              )}
            </span>
            <span className={Style.GoingGlobalText} dangerouslySetInnerHTML={{
              __html: t("That’s why our expertly tailored DTP solutions are here to accurately reproduce your most-loved materials and effectively distribute them on an international scale, so you can stop wasting time on “redos” and start benefiting from your new platform fast."),
            }}>
            </span>
          </div>
          <div className={Style.ImgContainer}>
          <img
              src={'/images/services/DTPImage.png'}
              alt="LocalizationImage"
              width="390"
              height="212"
            ></img>{" "}
          </div>
        </div>
        <div className={Style.BlueBackgroundSection}>
          <span className={Style.BlueBackgroundSectionTitle}>
            {t("You Bring The Content, We’ll Bring The…")}
          </span>
          <ul>
            <li className={Style.BlueBackgroundSectionItem}>
              {t(
                "Expert writers and editors who have specially trained to develop content across multilingual platforms, ensuring your work is transcribed with total accuracy"
              )}
            </li>
            <li className={Style.BlueBackgroundSectionItem400}>
              {t(
                "Leading graphic design and web development professionals with a keen eye for detail, working to preserve the integrity of your original design on a new platform"
              )}
            </li>
            <li className={Style.BlueBackgroundSectionItem}>
              {t(
                "In-depth analysis from localization specialists who work directly with you to tailor your content to specific audiences around the globe"
              )}
            </li>
            <li className={Style.BlueBackgroundSectionItem400}>
              {t(
                "Intuitive software tools that empower you to adjust your layout and make any necessary changes in just a few quick clicks"
              )}
            </li>
          </ul>
          <span className={Style.BlueBackgroundSectionFootLine}>
            {t("…available to you any time, any place - guaranteed.")}
          </span>
        </div>
        <span className={Style.YourVision} dangerouslySetInnerHTML={{
              __html: t("Your Content"),
            }}></span>
        {/* <span className={Style.OurResources}>{t("Made Globally-Friendly")}</span> */}
        <div className={Style.cardsContainer}>
          <HomeCardItem
            // image={DTPImage}
            title={t("Company Guides")}
            content={t(
              "Our team recreates and reproduces existing training materials including company conduct guides and intensive workbooks, so you can maintain consistency with international new hires."
            )}
          />
          <HomeCardItem
            // image={DTPImage}
            title={t("Marketing Materials")}
            content={t(
              "We reimagine your most powerful marketing messages and translate them for international audiences, opening your business up to endless new growth possibilities without going back to the drawing board."
            )}
          />
          <HomeCardItem
            // image={DTPImage}
            title={t("Instructional Manuals")}
            content={t(
              "Give your products the global treatment with reliable accuracy. Our team works to transcribe instructional materials in precise detail, reducing the likelihood of returns or complaints from international customers."
            )}
          />
        </div>
      </ServiceLayout>
    </div>
  );
};
