import React from "react";
import { Button } from "../../../Components/Button";
import Style from "./HomeHeader.module.css";
import { useTranslation } from "react-i18next";
import "./Global.css";
import { useContext } from "react";
import ViewportContext from "../../../Shared/ViewportContext";
import { useHistory } from "react-router";

export const HomeHeader = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const MyWidth = useContext(ViewportContext).myWidth;
  return (
    <>
      <div className={Style.HomeHeaderContainer}>
        <span
          className={Style.HomeTitle}
          dangerouslySetInnerHTML={{
            __html: t("We Break Language Barriers So You Can Grow On A Global Scale"),
          }}
        ></span>
        <span className={Style.HomeSubtitle}>{t("At iMachinary, we work hand in hand with businesses of all sizes to create streamlined translation and localization solutions bespoke to your unique needs. Together, we’re making the world a more connected place for entrepreneurs and consumers alike.")}</span>
      </div>
      <div className={Style.HomeImgInnerContainer}>
        <Button
          style={{
            padding: MyWidth > 480 ? "11px 36px 11px 36px" : "3px 8px 3px 8px",
            fontWeight: "500",
            fontSize: MyWidth > 480 ? "1.125rem" : "0.8rem",
            borderRadius: MyWidth > 480 ? '16px' : '10px',
            minHeight: MyWidth > 480 ? '44px' : '30px',
          }}
          onClick={() => {
            history.push("/process");
          }}
        >
          {" "}
          {t("Let's take you to the world")}{" "}
        </Button>
        <div className={Style.HomeImgContainer}>
          <img
            className={Style.BackgroundPlaceHolder}
            src={'/images/home/BackgroundPlaceHolder.jpg'}
            alt='Background Placeholder'
          ></img>
        </div>
      </div>
    </>
  );
};
