import Style from "./AboutUs.module.css";
import { Container } from "../../Components/Container/Container";
import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import ViewportContext from "../../Shared/ViewportContext";
import "./styles.css";
import { Button } from "../../Components/Button";
import { useHistory } from "react-router";
import ReactGA from 'react-ga4';

export const AboutUs = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const MyWidth = useContext(ViewportContext).myWidth;
  const [orientation, setOrientation] = useState("vertical");

  const setScreenWidth = () => {
    if (window.innerWidth < 768 && orientation === "vertical") {
      setOrientation("horizontal");
    }
    if (window.innerWidth >= 768 && orientation === "horizontal") {
      setOrientation("vertical");
    }
  };
  
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/about-us"});
  }, [])

  useEffect(() => {
    window.removeEventListener("resize", setScreenWidth);
    window.addEventListener("resize", setScreenWidth);
  }, [orientation]);

  return (
    <>
      <Container style={{ backgroundColor: "#f5f5f7" }}>
        <div className={Style.innerContainer}>
          {MyWidth > 480 && (
            <span className={Style.Label1}>
              {`${t("Home").toUpperCase()}/${t("Company").toUpperCase()}`}
            </span>
          )}
          <span
            className={Style.Label2}
            dangerouslySetInnerHTML={{
              __html: t("You Bring The Bright Ideas And We’ll Handle The Rest"),
            }}
          ></span>
          <span className={Style.LabelSubtitle}>
            {t(
              "Expanding your business into international markets can lead to a treasure trove of revenue building opportunities, profitable collaborations and million dollar ideas."
            )}
          </span>

          <span className={Style.Paragraph1}>
            {t(
              "But for most small and medium sized business owners, the task of taking their already tight operation and pushing beyond the local scale can seem like an impossible daunting feat. But, it doesn’t have to be that way…"
            )}
          </span>

          <span className={Style.Paragraph2}>
            {t("iMachinary is on a mission.")}
          </span>
          <span
            className={Style.Paragraph3}
            dangerouslySetInnerHTML={{
              __html: t(
                "We aspire to create global business opportunities for brands of all sizes, one custom tailored strategy at a time."
              ),
            }}
          ></span>
          <span className={Style.Paragraph3}>
            {t(
              "Our industry leading coalition of linguists, strategists and IT professionals are here to take your brilliant business ideas and transform them into profitable operations made scalable for global consumption."
            )}
          </span>
          <span className={Style.Paragraph2}>
            {t("It all starts with one crystal clear message.")}
          </span>
          <span className={Style.Paragraph3}>
            {t(
              "First, our team works directly with you by assigning you a personal translator to oversee the process of creating powerful content that resonates with audiences around the world, so you can trust that each and every project has been expertly translated for maximum impact."
            )}
          </span>
          <span className={Style.Paragraph3}>
            {t(
              "Next, we utilize cutting edge technologies to build streamlined localization and internationalization systems designed for effortless scalability, giving you the freedom to expand your brand with each new global market."
            )}
          </span>
        </div>
      </Container>
      <div className={Style.text2Container}>
        <span className={Style.ParagraphInBlue1}>
          {t("Do you ever need an extra hand bringing your vision to life?")}
        </span>
        <span className={Style.ParagraphInBlue2}>
          {t("Our customer care staff is available to you 24/7, no matter what timezone you’re in.")}
        </span>
        <Button
          style={{
            padding: MyWidth > 480 ? "11px 36px 11px 36px" : "1px 8px 1px 8px",
            fontWeight: "500",
            fontSize: MyWidth > 480 ? "1.125rem" : "0.9rem",
            textAlign: 'center',
            margin: MyWidth > 480 ? "3%" : "8% 3% 3%",
            minHeight: MyWidth > 480 ? '44px' : '30px',
          }}
          onClick={() => {
            history.push("/contact-us");
          }}
        >
          {" "}
          {t("Contact Us Today")}{" "}
        </Button>
      </div>
      <Container style={{ backgroundColor: "#f5f5f7" }}>
      <span className={Style.ParagraphBottom}>
          {t("Bottom Line?")}
        </span>
      <div className={Style.text2ContainerBottom}>
        <span className={Style.ParagraphBottom1}>
          {t("iMachinary isn’t another automated translation tool. We’re a versatile extension of your team, committed to seeing you succeed both at home and abroad.")}
        </span>
        <div className={Style.ButtonContainer}>
          <Button
          color={"primary"}
          style={{
            textAlign: 'center',
            margin: '3% 0 3%',
            padding: MyWidth > 800 ? "11px 36px 11px 36px" : "9px 10px 8px 10px",
            fontWeight: "500",
            fontSize: MyWidth > 800 ? "1.25rem" : "0.9rem",
            flexShrink: 0,
            minHeight: MyWidth > 480 ? '44px' : '30px', 

          }}
          onClick={() => {
            history.push("/contact-us");
          }}
        >
          {" "}
          {t("Discover The Difference")}{" "}
        </Button>
        </div>
        
      </div>
      </Container>
    </>
  );
};
