import styles from "./IconButton.module.scss";

const IconButton = ({ variant, size, onClick, style, ...props }) => (
  <button
    type="button"
    className={`${styles.iconButton} ${styles[`iconButton-${variant}`]} ${
      styles[`iconButton-${size}`]
    }`}
    onClick={onClick}
    style={style}
  >
    <props.icon className={styles.icon} />
  </button>
);

IconButton.defaultProps = {
  variant: "default",
  size: "regular",
};

export { IconButton };
