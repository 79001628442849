import React from "react";
import Style from "./Footer.module.css";
import LinkedinIcon from "./Icons/Linkedin.svg";
import FacebookIcon from "./Icons/Facebook.svg";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import ViewportContext from "../../Shared/ViewportContext";

const Footer = () => {
  const MyWidth = useContext(ViewportContext).myWidth;
  const { t } = useTranslation();

  return (
    <div className={Style.footerContainer}>
      <div className={Style.footerAddressContainer}>
        <span className={Style.footerAddress}>
          iMachinary LLC - 7345 W SAND LAKE RD, STE 210 OFFICE 502, ORLANDO, FL
          32819 US.
        </span>
        </div>
      {MyWidth > 750 && (
         <div className={Style.footerSocial}>
          <span className={Style.Followus}>{t("Follow us:")}</span>
          <img
            width={21}
            className={Style.IconButton}
            src={LinkedinIcon}
            alt="Linkedin Logo"
            onClick={() => {
              window.open(
                "https://www.linkedin.com/company/imachinary/",
                "_blank"
              );
            }}
          />
          {/* <img
            width={21}
            className={Style.IconButton}
            src={FacebookIcon}
            alt="Facebook Logo"
            onClick={() => {
              window.open("https://www.facebook.com/imachinary/", "_blank");
            }}
            /> */}
          </div>
      )}
      {/* <div className={Style.footerPolicy}>
        <span className={Style.PrivacyPolicyTermsofuse}>
          {t('Privacy Policy')} | {t('Terms of use')}
        </span>
      </div> */}
      
    </div>
  );
};
export { Footer };
