import React, { useRef, useEffect } from "react";
import styles from "./Select.module.scss";

function Select({ value, options, placeholderText, onChange, width, error, ref, style }) {
  const containerRef = useRef();

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container?.getElementsByClassName(styles.placeholder)[0]?.remove();
      container?.getElementsByClassName(styles["select-selected"])[0]?.remove();
      container?.getElementsByClassName(`${styles["select-items"]}`)[0]?.remove();
    }
  }, [options]);

  useEffect(() => {
    const container = containerRef.current;
    //
    if (container) {
      let selectElement, placeholder, selectedItem, listDiv, optionDiv;
      selectElement = container.getElementsByTagName("select")[0];
      const placeholderTextEl = container.getElementsByClassName(styles.placeholderText);

      placeholder = container.getElementsByClassName(styles.placeholder);

      if (!placeholder.length) {
        placeholder = document.createElement("DIV");
        placeholder.setAttribute("class", styles.placeholder);
        placeholder.innerHTML = placeholderTextEl[0].innerHTML;
        container.appendChild(placeholder);
      }

      selectedItem = container.getElementsByClassName(styles["select-selected"])[0];

      if (!selectedItem) {
        /* For each element, create a new DIV that will act as the selected item: */
        selectedItem = document.createElement("DIV");
        selectedItem.setAttribute("class", styles["select-selected"]);
        selectedItem.innerHTML = selectElement.options[selectElement.selectedIndex].innerHTML;
        container.appendChild(selectedItem);
      }

      if (selectedItem.innerHTML !== placeholderText) {
        placeholder.setAttribute("class", styles["select-hide"]);
      }

      listDiv = container.getElementsByClassName(`${styles["select-items"]}`)[0];

      if (!listDiv) {
        /* For each element, create a new DIV that will contain the option list: */
        listDiv = document.createElement("DIV");
        listDiv.setAttribute("class", `${styles["select-items"]} ${styles["select-hide"]}`);

        /*Add a fake div to get the proper margin top without exposing de bottom border
    of the placeholder or the selected item*/
        let fakeDiv = document.createElement("DIV");
        fakeDiv.setAttribute("class", styles.fakeDiv);
        listDiv.appendChild(fakeDiv);

        for (let j = 1; j < selectElement.length; j++) {
          /* For each option in the original select element,
    create a new DIV that will act as an option item: */
          optionDiv = document.createElement("DIV");
          optionDiv.innerHTML = selectElement.options[j].innerHTML;
          optionDiv.value = selectElement.options[j].value;

          optionDiv.addEventListener("click", function (e) {
            /* When an item is clicked, update the original select box,
        and the selected item: */
            let select = this.parentNode.parentNode.getElementsByTagName("select")[0];
            let selected = this.parentNode.previousSibling;
            onChange(e);
            for (let i = 0; i < select.length; i++) {
              if (select.options[i].innerHTML == this.innerHTML) {
                select.selectedIndex = i;
                selected.innerHTML = this.innerHTML;
                let y = this.parentNode.getElementsByClassName(styles["same-as-selected"]);
                for (let k = 0; k < y.length; k++) {
                  y[k].removeAttribute("class");
                }
                this.setAttribute("class", styles["same-as-selected"]);
                break;
              }
            }
            selected.click();
            placeholder.setAttribute("class", styles["select-hide"]);
          });
          listDiv.appendChild(optionDiv);
        }
        container.appendChild(listDiv);

        selectedItem.addEventListener("click", function (e) {
          /* When the select box is clicked, close any other select boxes,
    and open/close the current select box: */
          e.stopPropagation();
          closeAllSelect(this);
          this.nextSibling?.classList?.toggle(styles["select-hide"]);
          this.classList.toggle(styles["select-arrow-active"]);
        });
      }
      function closeAllSelect(element) {
        /* A function that will close all select boxes in the document,
  except the current select box: */
        let index = [];
        let selectItems = document.getElementsByClassName(styles["select-items"]);
        let selectedItems = document.getElementsByClassName(styles["select-selected"]);
        for (let i = 0; i < selectedItems.length; i++) {
          if (element == selectedItems[i]) {
            index.push(i);
          } else {
            selectedItems[i].classList.remove(styles["select-arrow-active"]);
          }
        }
        for (let i = 0; i < selectItems.length; i++) {
          if (index.indexOf(i)) {
            selectItems[i].classList.add(styles["select-hide"]);
          }
        }
      }
      document.addEventListener("click", closeAllSelect);
    }
  }, [containerRef.current, options]);

  return (
    <div style={{ display: "flex", flexDirection: "column", width: width, maxWidth: '100%' }}>
      <div
        className={styles.selectStyle}
        ref={containerRef}
        style={{
          ...style,
          width: width,
        }}
      >
        <select ref={ref} value={value}>
          {options?.length > 0 && (
            <>
              <option className={styles.placeholderText}>{placeholderText}</option>
              {options.map((option) => {
                return <option value={option.value || option}>{option.label || option}</option>;
              })}
            </>
          )}
        </select>
      </div>
      <span className={styles.error}>{error}</span>
    </div>
  );
}

Select.defaultProps = {
  placeholderText: "placeholder",
  // width: "384px",
  ref: null,
};

export { Select };
