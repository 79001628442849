import React from "react";
import svgSpinner from "./Spinner.svg";
import Style from "./Spinner.module.css";

const Spinner = (props) => {
  const { percentage } = props;

  return (
    <div className={Style.SpinnerContainer}>
      <img width={76} src={svgSpinner} alt="React Logo" />
      <span className={Style.SpinnerPorcentage}>
        {percentage ? percentage + "%" : ""}
      </span>
    </div>
  );
};

export { Spinner };
