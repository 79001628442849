import React, { useState, useEffect, useContext } from "react";
import { Checkbox } from "../Checkbox/Checkbox";
import styles from "./SelectCard.module.css";
import localImage from "./default.png";
import { Button } from "../Button";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ViewportContext from "../../Shared/ViewportContext";

function SelectCard({
  link,
  onChange,
  title = "Internationalization",
  checked,
  value,
  image,
}) {
  const MyWidth = useContext(ViewportContext).myWidth;
  const { t } = useTranslation();
  const [defineFontSize, setDefineFontSize] = useState();
  const history = useHistory();
  if (!image) {
    image = localImage;
  }
  console.log(image)
  const setFontSize = () => {
    if (MyWidth < 768) {
      setDefineFontSize("12px");
    }
    if (MyWidth >= 768) {
      setDefineFontSize("18px");
    }
  };

  useEffect(() => {
    setFontSize();
  }, [MyWidth]);

  const handleClick = (e) => {
    e.preventDefault();
    history.push(link);
  };

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={image} alt={t(`${title}`)} className={styles.image} />
      </div>
      <div className={styles.midContainer}>
        <h3 className={styles.title}>{t(`${title}`)}</h3>
        <div className={styles.buttonContainer}>
          <Button
            variant="arrow"
            children={t("More Info")}
            onClick={handleClick}
            style={{ fontWeight: 600, fontSize: '0.875rem', lineHeight: '1.0625rem' }}
            color="primary"
          />
        </div>
      </div>
      <div className={styles.checkContainer}>
        <Checkbox
          variant={MyWidth < 768 ? "small" : "normal"}
          onChange={onChange}
          checked={checked}
          value={value}
          round={true}
        />
      </div>
    </div>
  );
}

export default SelectCard;
