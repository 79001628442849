import React, { useEffect } from "react";

// Local
import { Footer } from "../Footer/Footer";

// Styles
import styles from "./Skeleton.module.css";
import { NavBar } from "../NavBar";
import { NavBarButton } from "../NavBarButton";
import { NavBarDivider } from "../NavBarDivider";
import { useHistory, useLocation } from "react-router";

import { useTranslation } from "react-i18next";
import ViewportContext from "../../Shared/ViewportContext";
import { useContext } from "react";

const Skeleton = (props) => {
  const MyWidth = useContext(ViewportContext).myWidth;
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <>
      <NavBar
        theme={props.theme}
        onStartNowClick={() => history.push("/process")}
      >
        <NavBarButton text={t("Services")}>
          <div style={{ height: "28px" }}>
            <NavBarButton
              text={t("Adaptive Internationalization")}
              href="/services/internationalization"
              IsNotBold={location.pathname !== "/services/internationalization"}
            />
          </div>
          <div style={{ height: "28px" }}>
          <NavBarButton
            text={t("Precise Localization")}
            href="/services/localization"
            IsNotBold={location.pathname !== "/services/localization"}
          />
          </div>
          <div style={{ height: "28px" }}>
          <NavBarButton
            text={t("Engineering Solutions")}
            href="/services/engineering"
            IsNotBold={location.pathname !== "/services/engineering"}
          />
          </div>
          <div style={{ height: "28px" }}>
          <NavBarButton
            text={t("Simplified Desktop Publishing")}
            href="/services/dtp"
            IsNotBold={location.pathname !== "/services/dtp"}
          />
          </div>
          <div style={{ height: "28px" }}>
          <NavBarButton
            text={t("International Consulting")}
            href="/services/consulting"
            IsNotBold={location.pathname !== "/services/consulting"}
          />
          </div>
          <div style={{ height: "28px" }}>
          <NavBarButton
            text={t("High-Converting Copywriting")}
            href="/services/copywriting"
            IsNotBold={location.pathname !== "/services/copywriting"}
          />
          </div>
        </NavBarButton>
        {/* <NavBarButton text={t("Industries")}>
          <NavBarButton
            text={t("Entertainment")}
            href="/industries/entertainment"
          />
          <NavBarButton text={t("Education")} href="/industries/education" />
          <NavBarButton text={t("Legal")} href="/industries/legal" />
          <NavBarButton text={t("Finance")} href="/industries/finance" />
          <NavBarButton text={t("e-Commerce")} href="/industries/e-commerce" />
          <NavBarButton text={t("Healthcare")} href="/industries/healthcare" />
        </NavBarButton> */}
        {/* <NavBarButton text={t("Company")}>
          <NavBarDivider title={t("IMACHINARY")}>
            <NavBarButton text={t("About us")} href='/about-us' /> */}
        {/* <NavBarButton text={t("iMachinary")} /> */}
        {/* </NavBarDivider> */}
        {/* <NavBarDivider title={t("RESOURCES")}>
            <NavBarButton text={t("Tools")} />
            <NavBarButton text={t("FAQS")} />
          </NavBarDivider> */}
        {/* </NavBarButton> */}
        <NavBarButton text={t("Company")} href="/about-us" />
        <NavBarButton text={t("Work with us")} href="/work-with-us" />
        {MyWidth < 1025 && (
          <NavBarButton text={t("Contact us")} href="/contact-us" />
        )}
      </NavBar>
      <main className={styles.content}>
        {props.children} <Footer {...props} />
      </main>
    </>
  );
};

export { Skeleton };
