import Style from "./ServicesInternationalization.module.css";
import { ServiceLayout } from "../../Components/ServiceLayout";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import HomeCardItem from "../../Components/HomeCardItem/HomeCardItem";
import { Carousel } from "../../Components/Carousel";
import ReactGA from 'react-ga4';
import { useEffect } from "react";

export const ServicesInternationalization = () => {
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/service/consulting" });
    ReactGA.event({
      category: "ServiceCategory",
      action: "View Internationalization",
      label: "Internationalization", // optional
      value: 0, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    document.title = "iMachinary - Internationalization"
  }, [])
  
  return (
      <ServiceLayout
        buttonText={t("Go Global")}
        title={`${t("Home").toUpperCase()} / ${t("Services").toUpperCase()} / ${t(
          "Adaptive Internationalization"
        ).toUpperCase()}`}
        subtitle={t("Adaptive Internationalization")}
        handleClick={() => {
          history.push("/process", { services: ["Internationalization"] });
        }}
      >
        <div className={Style.TwoColContainer}>
          <div className={Style.GoingGlobalContainer}>
            <span className={Style.GoingGlobalTag}>
              {t("“Going Global, Made Easy.”")}
            </span>
            <span className={Style.GoingGlobalText}>
              {t(
                "In today’s rapidly evolving global marketplace, the opportunities that accompany operating at the international level can transform your business from barely getting by to experiencing noticeable growth with each quarter."
              )}
            </span>
            <span className={Style.GoingGlobalText}>
              {t(
                "iMachinary’s internationalization department goes beyond standard language translations to understand your end users at a core level, so you can craft powerful messages that translate into direct sales."
              )}
            </span>
          </div>
          <div className={Style.ImgContainer}>
            {" "}
            <img
              src={'/images/services/ServiceIntImage.png'}
              alt="ServiceIntImage"
              width="390"
              height="212"
            ></img>{" "}
          </div>
        </div>
        <div className={Style.BlueBackgroundSection}>
          <span className={Style.BlueBackgroundSectionTitle}>
            {t("Just One Internationalized Project Can Lead To…")}
          </span>
          <ul>
            <li className={Style.BlueBackgroundSectionItem}>
              {t(
                "Countless hours of labor saved using our streamlined in-house software that puts the power of growth in your hands"
              )}
            </li>
            <li className={Style.BlueBackgroundSectionItem400}>
              {t(
                "Thousands of new customers that relate to your translated marketing messages, leading to limitless growth potential"
              )}
            </li>
            <li className={Style.BlueBackgroundSectionItem}>
              {t(
                "Sleek, sophisticated website tools that make serving more customers as easy as one click"
              )}
            </li>
            <li className={Style.BlueBackgroundSectionItem400}>
              {t(
                "Bold creative visions that reflect the evolving nature of your newfound international platform"
              )}
            </li>
          </ul>
          <span className={Style.BlueBackgroundSectionFootLine}>
            {t("…all delivered in one custom tailored package.")}
          </span>
        </div>
        <span className={Style.YourVision}>{t("Your Vision.")}</span>
        <span className={Style.OurResources}>{t("Our Resources.")}</span>
        <div className={Style.carousel}>
        <Carousel>
          <HomeCardItem
            // image={ImageSliderInternationalization}
            title={t("Design Consulting")}
            content={t("We give you expert insight from leading graphic designers and web developers with experience in building profit-churning websites in every corner of the world.")}
          />
          <HomeCardItem
            // image={ImageSliderInternationalization}
            title={t("In-House Coding")}
            content={t("Our team of coding specialists work tireless to bring your online platform to life, one intricately placed line of code at a time.")}
          />
          <HomeCardItem
            // image={ImageSliderInternationalization}
            title={t("Multilingual Test Runs")}
            content={t(
              "We employ an elite team of linguists and software aficionados who take extra care to ensure your website runs flawlessly, no matter what language it’s viewed in."
            )}
          />
          <HomeCardItem
            // image={ImageSliderInternationalization}
            title={t("Content Translation")}
            content={t(
              "Your personal translation consultant takes creating accurate messages one step further, working directly with you to craft compelling content relevant to your new marketplace."
            )}
          />
          <HomeCardItem
            // image={ImageSliderInternationalization}
            title={t("Layout Localization")}
            content={t(
              "We take the finishing touches seriously. Our team works around the clock to make any necessary adjustments to all of your content, so it shows up perfectly every time."
            )}
          />
        </Carousel>
      </div>
        <div className={Style.FooterContainer}>
          <span className={Style.AllYourInternationalization}>
            {t("All your internationalization needs, all in one place.")}
          </span>
        </div>
      </ServiceLayout>
  );
};
