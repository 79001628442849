import React from "react";
import styles from "./HomeCardItem.module.css";


function HomeCardItem({ image, title, content }) {
  return (
    <div className={styles.cardContainer}>
      {/* <div className={styles.imageContainer}>
        <img src={image} alt={title} className={styles.image} />
      </div> */}
        <p className={styles.title} dangerouslySetInnerHTML={{
          __html: title,
        }}>
        </p>
        <p className={styles.content} dangerouslySetInnerHTML={{
              __html: content,
            }}></p>
    </div>
  );
}

export default HomeCardItem;
