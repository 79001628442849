import Style from "./ServicesCopywriting.module.css";
import { ServiceLayout } from "../../Components/ServiceLayout";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import HomeCardItem from "../../Components/HomeCardItem/HomeCardItem";
import ReactGA from 'react-ga4';
import { useEffect } from "react";


export const ServicesCopywriting = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/service/copywriting"});
    ReactGA.event({
      category: "ServiceCategory",
      action: "View Copywriting",
      label: "Copywriting", // optional
      value: 0, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    document.title = "iMachinary - Copywriting"
  }, [])

  return (
    <div className={Style.InnerContainer}>
      <ServiceLayout
        buttonText={t("Get It Written")}
        title={`${t("Home").toUpperCase()} / ${t("Services").toUpperCase()} / ${t(
          "High-Converting Copywriting"
        ).toUpperCase()}`}
        subtitle={t("High-Converting Copywriting")}
        handleClick={() => {
          history.push("/process", { services: ["Copywriting"] });
        }}
      >
        <div className={Style.TwoColContainer}>
          <div className={Style.GoingGlobalContainer}>
            <span className={Style.GoingGlobalTag}>
              {t("“Make Every Word Count.”")}
            </span>
            <span className={Style.GoingGlobalText}>
              {t(
                "When it comes to expanding your business, leveraging the power of the written word can make or break your ability to successfully attract new clients, customers and collaborators on a global scale."
              )}
            </span>
            <span className={Style.GoingGlobalText}>
              {t(
                "iMachinary employs a diverse team of expert multilingual copywriters who specialize in understanding the needs of each unique market, so you can create localized content that accurately represents your brand, regardless of language barriers."
              )}
            </span>
          </div>
          <div></div>
        </div>
        <span className={Style.OurResources}>{t("Put Our Wordsmiths To Work On…")}</span>
        <div className={Style.cardsContainer}>
          <HomeCardItem
            // image={ImageSlider}
            title={t("Technical Content & Instructions")}
            content={t(
              "We craft clear, precise technical materials that enlighten and engage your readers, no matter how complex the subject matter may seem."
            )}
          />
          <HomeCardItem
            // image={ImageSlider}
            title={t("Compelling Marketing Materials")}
            content={t(
              "From landing pages to email campaigns and every medium in between, we work with you to create scroll-stopping copy that moves your audience through the sales process with ease."
            )}
          />
          <HomeCardItem
            // image={ImageSlider}
            title={t("Consistent Brand Voice")}
            content={t(
              "Whether you’re a brand new start-up or an established business, our team is here to keep your messages consistent across all content and platforms, so you can build a reputation that speaks for itself."
            )}
          />
        </div>
      </ServiceLayout>
    </div>
  );
};
