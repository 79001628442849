import React from "react";
import Style from "./HomePreFooter.module.css";
import { Button } from "../../../Components/Button";
import { useTranslation } from "react-i18next";
import "./Global.css";
import { useContext } from "react";
import ViewportContext from "../../../Shared/ViewportContext";
import { useHistory } from "react-router";

export const HomePreFooter = () => {
  const history = useHistory();
  const MyWidth = useContext(ViewportContext).myWidth;
  const { t } = useTranslation();
  return (
    <>
      <div className={Style.MainContainer}>
        {/* <span
          className={Style.BlueSectionTitle}
          dangerouslySetInnerHTML={{
            __html: t("Cutting Edge Tech With A Human Touch"),
          }}
        /> */}
        {/* <div className={Style.BlueSectionSubContainer}>
          <span className={Style.BlueSectionSubtitle}>
            {t(
              "What’s the difference between a translation that misses the mark and one that resonates on a personal level?"
            )}
          </span>
        </div> */}
        <div className={Style.BodyContainer}>
          <span className={Style.BodyTitle}>
            {t("We do more than just translate your words.")}
          </span>
          <br />
          <div className={Style.TextAndButtonSection}>
            <span className={Style.BodyText} dangerouslySetInnerHTML={{
              __html: t("iMachinary crafts compelling brand messages designed to attract new opportunities for your business, on a global scale, without the need for chat bots or call centers."),
            }}>
          </span>
          <div className={Style.ButtonContainer}>
            <Button color={"primary"}
          style={{
            textAlign: 'center',
            margin: '3% 0 3%',
            padding: MyWidth > 800 ? "11px 36px 11px 36px" : "9px 10px 8px 10px",
            fontWeight: "500",
            fontSize: MyWidth > 800 ? "1.25rem" : "0.9rem",
            flexShrink: 0,
            minHeight: MyWidth > 480 ? '44px' : '30px',            
          }}
          onClick={() => {
            history.push("/contact-us");
          }}
        >
          {" "}
          {t("Get Started Today")}{" "}
        </Button>
          </div>
          
          </div>
          
        </div>
      </div>
    </>
  );
};
