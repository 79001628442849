import React, { useRef, useState, useEffect } from "react";
import _ from "lodash";
import styles from "./Checkbox.module.css";
import { FaCheck } from "react-icons/fa";
import { IconContext } from "react-icons";

export const Checkbox = ({
  checked,
  value,
  onChange,
  label,
  error,
  round,
  variant,
  labelClassName,
}) => {
  const ref = useRef();
  const [_checked, setChecked] = useState(checked || false);
  const [id] = useState(`checkbox-${_.uniqueId()}`);

  const onClick = () => {
    setChecked(!_checked);
    ref.current.click();
  };
  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", width: "fit-content" }}
    >
      <div className={styles.innerContainer}>
        <div
          id={_checked ? styles.checkboxChecked : styles.checkbox}
          className={`${round ? variant !== "small" ? styles.round : styles.roundSmall: ""} ${
            variant === "small" ? styles.variantSmall : styles.variantNormal
          }`}
          onClick={onClick}
        >
          <IconContext.Provider value={{ className: "" }}>
            <FaCheck
              size={round ? 20 : variant === "small" ? 13 : 14}
              style={{ fill: "white" }}
              id={styles.iconStyle}
            />
          </IconContext.Provider>
        </div>
        <input
          ref={ref}
          id={id}
          checked={_checked}
          type="checkbox"
          style={{ display: "none" }}
          onChange={onChange}
          value={value}
        />
        {label && (
          <label
            htmlFor={id}
            onClick={(e) => {
              e.preventDefault();
              onClick();
            }}
            style={{ cursor: "pointer" }}
            className={
              labelClassName
                ? error !== undefined
                  ? `${labelClassName} ${styles.error}`
                  : labelClassName
                : ""
            }
          >
            {label}
          </label>
        )}
      </div>
      <div className={styles.error}>{error}</div>
    </div>
  );
};
