import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Step1.module.css";
import { Button } from "../../Components/Button";
import SelectCard from "../SelectCard/SelectCard";
import { Container } from "../Container/Container";
import { Controller, useForm } from "react-hook-form";

export const Step1 = ({ onSubmit, state }) => {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues: state,
  });

  return (
    <Container style={{ backgroundColor: "#f5f5f7" }}>
      <div className={styles.innerContainer}>
        <span className={styles.Label1}>
          {`${t("Home").toUpperCase()}/${t("Start").toUpperCase()}`}
        </span>
        <span className={styles.Label2}>{t("Lets Choose")}</span>
        <p className={styles.text}>
          {t("Select the services you need for your project")}
        </p>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.cardsContainer}>
            <div className={styles.subCardsContainer}>
              <Controller
                name="services"
                control={control}
                render={({ field }) => (
                  <SelectCard
                    {...field}
                    link="/services/internationalization"
                    title="Adaptive Internationalization"
                    image='/images/step1/Internationalization.png'
                    value="Internationalization"
                    // For some reason codesandbox doesn't support `field.value.includes(...)`
                    checked={field.value?.some(
                      (existingValue) =>
                        existingValue === "Internationalization"
                    )}
                    onChange={(event) => {
                      if (event.target.checked) {
                        field.onChange([
                          ...(field.value || []),
                          event.target.value,
                        ]);
                      } else {
                        field.onChange(
                          field.value?.filter(
                            (value) => value !== event.target.value
                          )
                        );
                      }
                    }}
                  />
                )}
              />
              <Controller
                name="services"
                control={control}
                render={({ field }) => (
                  <SelectCard
                    {...field}
                    link="/services/engineering"
                    title="Engineering Solutions"
                    image='/images/step1/Engineering.png'
                    value="Engineering"
                    // For some reason codesandbox doesn't support `field.value.includes(...)`
                    checked={field.value?.some(
                      (existingValue) => existingValue === "Engineering"
                    )}
                    onChange={(event) => {
                      if (event.target.checked) {
                        field.onChange([
                          ...(field.value || []),
                          event.target.value,
                        ]);
                      } else {
                        field.onChange(
                          field.value?.filter(
                            (value) => value !== event.target.value
                          )
                        );
                      }
                    }}
                  />
                )}
              />
              <Controller
                name="services"
                control={control}
                render={({ field }) => (
                  <SelectCard
                    {...field}
                    link="/services/consulting"
                    title="International Consulting"
                    image='/images/step1/Consulting.png'
                    value="Consulting"
                    // For some reason codesandbox doesn't support `field.value.includes(...)`
                    checked={field.value?.some(
                      (existingValue) => existingValue === "Consulting"
                    )}
                    onChange={(event) => {
                      if (event.target.checked) {
                        field.onChange([
                          ...(field.value || []),
                          event.target.value,
                        ]);
                      } else {
                        field.onChange(
                          field.value?.filter(
                            (value) => value !== event.target.value
                          )
                        );
                      }
                    }}
                  />
                )}
              />
            </div>
            <div className={styles.subCardsContainer}>
              <Controller
                name="services"
                control={control}
                render={({ field }) => (
                  <SelectCard
                    {...field}
                    link="/services/localization"
                    title="Precise Localization"
                    image='/images/step1/Localization.png'
                    value="Localization"
                    // For some reason codesandbox doesn't support `field.value.includes(...)`
                    checked={field.value?.some(
                      (existingValue) => existingValue === "Localization"
                    )}
                    onChange={(event) => {
                      if (event.target.checked) {
                        field.onChange([
                          ...(field.value || []),
                          event.target.value,
                        ]);
                      } else {
                        field.onChange(
                          field.value?.filter(
                            (value) => value !== event.target.value
                          )
                        );
                      }
                    }}
                  />
                )}
              />
              <Controller
                name="services"
                control={control}
                render={({ field }) => (
                  <SelectCard
                    {...field}
                    link="/services/dtp"
                    value={"Desktop Publishing"}
                    // For some reason codesandbox doesn't support `field.value.includes(...)`
                    checked={field.value?.some(
                      (existingValue) => existingValue === "Desktop Publishing"
                    )}
                    onChange={(event) => {
                      if (event.target.checked) {
                        field.onChange([
                          ...(field.value || []),
                          event.target.value,
                        ]);
                      } else {
                        field.onChange(
                          field.value?.filter(
                            (value) => value !== event.target.value
                          )
                        );
                      }
                    }}
                    title="Simplified Desktop Publishing"
                    image='/images/step1/DesktopPublishing.png'
                  />
                )}
              />
              <Controller
                name="services"
                control={control}
                render={({ field }) => (
                  <SelectCard
                    {...field}
                    link="/services/copywriting"
                    title="High-Converting Copywriting"
                    image='/images/step1/Copywriting.png'
                    value="Copywriting"
                    // For some reason codesandbox doesn't support `field.value.includes(...)`
                    checked={field.value?.some(
                      (existingValue) => existingValue === "Copywriting"
                    )}
                    onChange={(event) => {
                      if (event.target.checked) {
                        field.onChange([
                          ...(field.value || []),
                          event.target.value,
                        ]);
                      } else {
                        field.onChange(
                          field.value?.filter(
                            (value) => value !== event.target.value
                          )
                        );
                      }
                    }}
                  />
                )}
              />
            </div>
          </div>
          <p className={styles.subTitle}>{t("Need assistance?")}</p>
          <div className={styles.formButtons}>
            <div className={styles.textContainer}>
              <p className={styles.text}>
                {t(
                  "We can help you to choose the best option which adapts to your project."
                )}
                <br />
                {t("Skip the selection and continue with “Next” button.")}
              </p>
            </div>
            <div className={styles.btnContainer}>
              <Button
                htmlType="submit"
                color="primary"
                style={{ minWidth: "140px" }}
              >
                {t("Next")}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};
