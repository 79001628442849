import React from "react";
import styles from "./HomeEntryItem.module.css";
import { useContext } from 'react';
import ViewportContext from '../../Shared/ViewportContext'

function HomeEntryItem({ title, image, content }) {
  const MyWidth = useContext(ViewportContext).myWidth;
  return (
    <div className={styles.container}>
      {MyWidth > 480 &&
      <div className={styles.imageContainer}>
        <img src={image} className={styles.image} alt={title} />
      </div>}
      <div className={styles.textContainer}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.content}>{content}</p>
      </div>
      {MyWidth <= 480 &&
      <div className={styles.imageContainer}>
        <img src={image} className={styles.image} alt={title} />
      </div>}
    </div>
  );
}

export default HomeEntryItem;
