import { BrowserRouter, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Skeleton } from "../Components/Skeleton";
import { Home } from "../Scenes/Home/Home";

import { ServicesEngineering } from "../Scenes/ServiceEngineering/ServicesEngineering";
import NotFound404 from "../Scenes/NotFound404/NotFound404";
import { ServicesConsulting } from "../Scenes/ServiceConsulting/ServicesConsulting";
import { ContactUs } from "../Scenes/ContactUs";

import { ServicesDTP } from "../Scenes/ServicesDTP/ServicesDTP";
import { AboutUs } from "../Scenes/AboutUs/AboutUs";
import { ServicesLocalization } from "../Scenes/ServiceLocalization/ServicesLocalization";
import { ServicesInternationalization } from "../Scenes/ServiceInternationalization/ServicesInternationalization";
import { ServicesCopywriting } from "../Scenes/ServiceCopywriting/ServicesCopywriting";
import SelectCard from "../Components/SelectCard/SelectCard";
import { Step1 } from "../Components/Step1/Step1";
import { WorkWithUs } from "../Scenes/WorkWithUs/WorkWithUs";
import { ProcessStep2 } from "../Components/ProcessStep2/ProcessStep2";
import { Process } from "../Scenes/Process/Process";
import { WorkWithUsForm } from "../Scenes/WorkWithUsForm/WorkWithUsForm";
export const history = createBrowserHistory();


const AppRouter = (props) => {
  return (
    <BrowserRouter>
      <Switch className='AppRouter'>
        <Route
          exact
          path='/'
          render={(props) => (
            <Skeleton {...props}>
              <Home {...props} />
            </Skeleton>
          )}
        />
        <Route
          path='/services/engineering'
          render={(props) => (
            <Skeleton {...props} theme={"light"}>
              <ServicesEngineering {...props} />
            </Skeleton>
          )}
        />
        <Route
          exact
          path='/services/consulting'
          render={(props) => (
            <Skeleton {...props} showContactFooter={true} theme={"light"}>
              <ServicesConsulting />
            </Skeleton>
          )}
        />
        <Route
          exact
          path='/services/localization'
          render={(props) => (
            <Skeleton {...props} theme={"light"}>
              <ServicesLocalization {...props} />
            </Skeleton>
          )}
        />
        <Route
          exact
          path='/services/internationalization'
          render={(props) => (
            <Skeleton {...props} theme={"light"}>
              <ServicesInternationalization {...props} />
            </Skeleton>
          )}
        />
        <Route
          exact
          path='/services/copywriting'
          render={(props) => (
            <Skeleton {...props} theme={"light"}>
              <ServicesCopywriting {...props} />
            </Skeleton>
          )}
        />
        <Route
          exact
          path='/services/dtp'
          render={(props) => (
            <Skeleton {...props} showContactFooter={true} theme={"light"}>
              <ServicesDTP />
            </Skeleton>
          )}
        />
        <Route
          exact
          path='/about-us'
          render={(props) => (
            <Skeleton {...props} showContactFooter={true} theme={"light"}>
              <AboutUs />
            </Skeleton>
          )}
        />
        <Route
          exact
          path='/work-with-us'
          render={(props) => (
            <Skeleton {...props} showContactFooter={true} theme={"light"}>
              <WorkWithUs />
            </Skeleton>
          )}
        />
        <Route
          exact
          path='/work-with-us/new'
          render={(props) => (
            <Skeleton {...props} showContactFooter={true} theme={"light"}>
              <WorkWithUsForm />
            </Skeleton>
          )}
        />
        <Route
          exact
          path='/contact-us'
          render={(props) => (
            <Skeleton {...props} theme={"light"}>
              <ContactUs />
            </Skeleton>
          )}
        />
        <Route
          path='/process'
          render={() => (
            <Skeleton theme={"light"}>
              <Process />
            </Skeleton>
          )}
        />
        <Route
          path='*'
          render={(props) => (
            <Skeleton {...props}>
              <NotFound404 />
            </Skeleton>
          )}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
