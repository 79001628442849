import Style from "./ThankYou.module.css";
import ThankYouSvg from "./Components/ThankYou.svg";
import { Button } from "../Button/index";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

export const ThankYou = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { Paragraph, title } = props;
  return (
    <div className={Style.ThankYouContainer} style={Style}>
      <div>
        <img
          className={Style.ThankYouSvg}
          src={ThankYouSvg}
          alt="Thank You Logo"
        />
      </div>
      <span className={Style.ThankYouLabel}>{title || t("Thank you!")}</span>
      <span className={Style.ThankYouParagraph}>
        {Paragraph || (
          <>
            {t("Your message has been successfully sent.")} <br />{" "}
            {t("We will contact you very soon.")}{" "}
          </>
        )}
      </span>
      <div className={Style.ButtonGetItContainer}>
        <Button
          onClick={() => history.push("/")}
          color={"primary"}
          style={{ fontWeight: "normal" }}
        >
          {t("Ok")}
        </Button>
      </div>
    </div>
  );
};
