import React, { useEffect, useState } from "react";
import styles from "./TextArea.module.scss";

const TextArea = ({
  value,
  placeholderText = "Tell us about your project",
  onChange,
  width = "384px",
  maxLength,
  error,
}) => {
  const [characters, setCharacters] = useState(0);
  const [alertColor, setAlertColor] = useState("");
  const [charactersColor, setCharactersColor] = useState("#a0a0a0");

  useEffect(() => {
    if (error !== undefined) {
      setAlertColor("#ff7b73");
    } else {
      setAlertColor("");
    }
  }, [error]);

  const handleChange = (e) => {
    setCharacters(e.target.value.length);
    onChange(e);
  };

  useEffect(() => {
    if (characters >= maxLength) {
      setCharactersColor("#ff7b73");
    } else if (characters < maxLength && charactersColor !== "#a0a0a0") {
      setCharactersColor("#a0a0a0");
    }
  }, [characters]);

  return (
    <div className={styles.container} style={{ width: width }}>
      <>
        <textarea
          value={value}
          placeholder={placeholderText}
          name="input"
          autoComplete="off"
          className={styles.textArea}
          onChange={handleChange}
          maxLength={maxLength}
          style={{ border: `solid 1px`, borderColor: `${alertColor}` }}
        />
        {/* <label
          htmlFor="input"
          className={styles.label}
          style={{ color: `${alertColor}` }}
        >
          {placeholderText}
        </label> */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p
            className={styles.labelError}
            style={{
              textAlign: "left",
              color: "#ff7b73",
              fontSize: "14px",
              fontWeight: "600",
              marginTop: "5px",
              paddingLeft: "27px",
            }}
          >
            {error}
          </p>
          {maxLength && (
            <p
              style={{
                textAlign: "right",
                color: `${charactersColor}`,
                fontSize: "14px",
                marginTop: "5px",
                paddingRight: "10px",
              }}
            >
              {`${characters} of ${maxLength} total characters`}
            </p>
          )}
        </div>
      </>
    </div>
  );
};

export { TextArea };
