import styles from "./Typography.module.css";

const Typography = ({ style, variant, children }) => (
  <div className={styles[variant]} style={style}>
    {children}
  </div>
);

Typography.defaultProps = {
  variant: "h1",
};

export { Typography };
