import "./App.css";
import { Helmet } from "react-helmet";
import AppRouter from "./Shared/AppRouter";
import { useEffect, useState } from "react";
import ViewportContext from './Shared/ViewportContext'

import ReactGA from 'react-ga4';
ReactGA.initialize("G-VCFXV8D999");

function App() {
  const [MyWidth, setMyWidth] = useState(1080);
  const [MyHeight, setMyHeight] = useState(0);

  const handleResize = () => {
   setMyWidth(window.innerWidth)
   setMyHeight(window.innerHeight)
  };

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize, false);
    window.addEventListener('load', handleResize, false);
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>iMachinary</title>
        <link rel="shortcut icon" href={"./favicon.ico"} />        
      </Helmet>
      <ViewportContext.Provider value={{myWidth: MyWidth, myHeight: MyHeight}}>
      <AppRouter/>
      </ViewportContext.Provider>
    </div>
  );
}

export default App;
