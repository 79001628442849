import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ProcessStep3.module.scss";
import { useForm, Controller } from "react-hook-form";
import { Button } from "../../Components/Button";
import { Input } from "../../Components/Input";
import { Checkbox } from "../../Components/Checkbox/Checkbox";
import { Container } from "../Container/Container";
import { useHistory } from "react-router";
import ViewportContext from "../../Shared/ViewportContext";

const ProcessStep3 = ({ onSubmit, data, setData }) => {
  const viewportWidth = useContext(ViewportContext).myWidth;
  const history = useHistory();

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm({ defaultValues: data });

  const { t } = useTranslation();

  return (
    <Container style={{ backgroundColor: "#f5f5f7" }}>
      <div className={styles.innerContainer}>
        <span className={styles.Label1}>
          {" "}
          {`${t("Home").toUpperCase()}/${t("Start").toUpperCase()}/${t(
            "Your project"
          ).toUpperCase()}/${t("About You").toUpperCase()}`}
        </span>
        <span className={styles.Label2}>{t("About You")}</span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="fullname"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholderText={t("Full name") + "*"}
                error={errors[field.name]?.message}
              />
            )}
            rules={{ required: t("This field is required") }}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholderText={t("Email") + "*"}
                type="email"
                error={errors[field.name]?.message}
              />
            )}
            rules={{
              required: t("This field is required"),
              validate: {
                isEmail: (v) => {
                  if (
                    !String(v)
                      .toLowerCase()
                      .trim()
                      .match(
                        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i
                      )
                  ) {
                    return "The email is not valid";
                  }

                  return true;
                },
              },
            }}
          />
          <Controller
            name="company"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholderText={t("Company")}
                error={errors[field.name]?.message}
              />
            )}
          />
          {/* <div className={styles.terms}>
            <Controller
              name="terms"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value ? true : false}
                  error={errors[field.name]?.message}
                  label={t("Agree terms and privacy")}
                />
              )}
              rules={{ required: t("You must accept the terms") }}
            />
          </div> */}
          <div className={styles.formButtons}>
            <Button
              color="secondary"
              style={{ minWidth: "140px" }}
              onClick={() => {
                setData({ ...data, ...getValues() });
                history.goBack();
              }}
            >
              {t("Go back")}
            </Button>
            <Button
              htmlType="submit"
              style={{ minWidth: "140px" }}
              onClick={handleSubmit(onSubmit)}
            >
              {t("Submit")}
            </Button>
          </div>
        </form>
      </div>
    </Container>
  );
};

export { ProcessStep3 };
