import Style from "./ServicesConsulting.module.css";
import { ServiceLayout } from "../../Components/ServiceLayout";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useEffect } from "react";
import ReactGA from 'react-ga4';

export const ServicesConsulting = () => {
  const { t } = useTranslation();
  const history = useHistory();


  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/service/consulting" });
    ReactGA.event({
      category: "ServiceCategory",
      action: "View Consulting",
      label: "Consulting", // optional
      value: 0, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    document.title = "iMachinary - Consulting"
  }, [])

  return (
    <div className={Style.InnerContainer}>
      <ServiceLayout
        buttonText={t("Let’s Get To Work")}
        title={`${t("Home").toUpperCase()} / ${t("Services").toUpperCase()} / ${t(
          "International Consulting"
        ).toUpperCase()}`}
        subtitle={t("International Consulting")}
        handleClick={() => {
          history.push("/process", { services: ["Consulting"] });
        }}
      >
        
          <div className={Style.GoingGlobalContainer}>
            <span className={Style.GoingGlobalTag}>
              {t("“We Bring Your Goals To Life.”")}
            </span>
            <span className={Style.GoingGlobalText}>
              {t(
                "We know from years of experience that expanding into the global marketplace can bring endless questions and leave room for doubt and confusion amongst your team."
              )}
            </span>
            <span className={Style.GoingGlobalText}>
              {t(
                "That’s why the iMachinary team is dedicated to working hand in hand with you to create an ultra smooth transition into the international industry that allows you to scale your business easily, no matter where you’re starting from."
              )}
            </span>
            <span className={Style.GoingGlobalText}>
              {t(
                "Most importantly, we provide you with a personal consultant who works to understand your vision at a core level, so you can trust that your finished products will maintain a precise representation of your message, tailored for international audiences."
              )}
            </span>
          </div>
        
        <div className={Style.BlueBackgroundSection}>
          <span className={Style.BlueBackgroundSectionTitle}>
            {t("Together, We Build Winning Strategies For…")}
          </span>
          <ul>
            <li className={Style.BlueBackgroundSectionItem}>
              {t(
                "Expertly localizing existing content and accurate translating materials for new languages"
              )}
            </li>
            <li className={Style.BlueBackgroundSectionItem400}>
              {t(
                "Implementing seamless international operations that take the priorities of your business and your new customer base into account"
              )}
            </li>
            <li className={Style.BlueBackgroundSectionItem}>
              {t(
                "Adaptable engineering solutions that allow you to navigate unforeseen challenges quickly and effectively"
              )}
            </li>
            <li className={Style.BlueBackgroundSectionItem400}>
              {t(
                "Sourcing international personnel and any necessary global tools, so you can grow your team with confidence"
              )}
            </li>
          </ul>
          <span className={Style.BlueBackgroundSectionFootLine}>
            {t("… all customized to your unique brand vision.")}
          </span>
        </div>
      </ServiceLayout>
    </div>
  );
};
