import Style from "./ServicesEngineering.module.css";
import { ServiceLayout } from "../../Components/ServiceLayout";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useEffect } from "react";
import ReactGA from 'react-ga4';

export const ServicesEngineering = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/service/engineering" });
    ReactGA.event({
      category: "ServiceCategory",
      action: "View Engineering",
      label: "Engineering", // optional
      value: 0, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    document.title = "iMachinary - Engineering"
  }, [])
  
  return (
    <div className={Style.InnerContainer}>
      <ServiceLayout
        buttonText={t("Let’s Get Started")}
        title={`${t("Home").toUpperCase()} / ${t("Services").toUpperCase()} / ${t(
          "Engineering Solutions"
        ).toUpperCase()}`}
        subtitle={t("Engineering Solutions")}
        handleClick={() => {
          history.push("/process", { services: ["Engineering"] });
        }}
      >
        
          <div className={Style.GoingGlobalContainer}>
            <span className={Style.GoingGlobalTag}>
              {t("“Better Systems, Better Results.”")}
            </span>
            <span className={Style.GoingGlobalText}>
              {t(
                "When it comes to doing business online, your success can be directly measured by the effectiveness of your systems."
              )}
            </span>
            <span className={Style.GoingGlobalText}>
              {t(
                "With a streamlined approach, you can quickly adapt to changing consumer demands and offer customized solutions that arrive one step ahead of your competition."
              )}
            </span>
            <span className={Style.GoingGlobalText} dangerouslySetInnerHTML={{
              __html: t("But without it, your team is at the mercy of outdated technologies that keep you locked in place, even as the marketplace around you continues to move forward."),
            }}>
            </span>
            <span className={Style.GoingGlobalTextBold}>
              {t(
                "That’s where we come in."
              )}
            </span>
            <span className={Style.GoingGlobalText}>
              {t(
                "iMachinary is here to alleviate the burden of complex software systems and provide you with simplified tools that make adjusting your operations swift, seamless and effortlessly effective."
              )}
            </span>
          </div>
        
        <div className={Style.BlueBackgroundSection}>
          <span className={Style.BlueBackgroundSectionTitle}>
            {t("We’re Your One Stop Shop For…")}
          </span>
          <ul>
            <li className={Style.BlueBackgroundSectionItem}>
              {t(
                "Building thorough documentation through screen grabs and other data saving protocols"
              )}
            </li>
            <li className={Style.BlueBackgroundSectionItem400}>
              {t(
                "Quick adjustments for localized software using our in-house team of digital dialogue experts"
              )}
            </li>
            <li className={Style.BlueBackgroundSectionItem}>
              {t(
                "Extracting and translating relevant content from your projects, so you can keep records of your best material"
              )}
            </li>
            <li className={Style.BlueBackgroundSectionItem400}>
              {t(
                "Reliable testing of all newly launched and existing websites, made to act as a stand-in for your target audience"
              )}
            </li>
          </ul>
          <span className={Style.BlueBackgroundSectionFootLine}>
            {t("…Made conveniently accessible in one API-integrated, intuitively designed platform that does the hard work for you.")}
          </span>
        </div>
      </ServiceLayout>
    </div>
  );
};
