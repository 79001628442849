import React, { useEffect, useRef, useState } from "react";
import { FaUpload } from "react-icons/fa";
import { IconButton } from "../IconButton";
import { Typography } from "../Typography";
import styles from "./InputFile.module.scss";

const InputFile = ({ label, onChange, error }) => {
  const labelRef = useRef(null);
  const [id] = useState(_.uniqueId());
  const [filename, setFilename] = useState(null);

  const handleChange = (e) => {
    setFilename(e.target.files[0].name);
    onChange(e);
  };

  return (
    <div className={styles.inputFile}>
      <label htmlFor={id} ref={labelRef}>
        <IconButton
          size="small"
          variant="ghost"
          icon={FaUpload}
          onClick={() => labelRef?.current?.click()}
        />
        <Typography variant="b4">{label}</Typography>
      </label>
      <input id={id} onChange={handleChange} type="file" />
      {filename}
      <span className={styles.error}>{error}</span>
    </div>
  );
};

export { InputFile };
