import React from "react";
import Style from "./HomeBlueSection.module.css";
import { Button } from "../../../Components/Button";
import { useTranslation } from "react-i18next";
import "./Global.css";
import { useContext } from "react";
import ViewportContext from "../../../Shared/ViewportContext";
import { useHistory } from "react-router";

export const HomeBlueSection = () => {
  const history = useHistory();
  const MyWidth = useContext(ViewportContext).myWidth;
  const { t } = useTranslation();
  return (
    <div className={Style.BlueSectionContainer}>
      <span
        className={Style.BlueSectionTitle}
        dangerouslySetInnerHTML={{
          __html: t("Cutting Edge Tech With A Human Touch"),
        }}
      />
      <span className={Style.BlueSectionSubtitle}>
        {t(
          "What’s the difference between a translation that misses the mark and one that resonates on a personal level?"
        )}
      </span>
      <div className={Style.BlueSectionBodyContainer}>
        <span className={Style.BlueSectionBodyTitle}>{t("Humanization.")}</span>
        <br />
        <span className={Style.BlueSectionBodyText}>
          {t(
            "Without it, your brand’s message becomes lost in an endless sea of automated translations, hindering your ability to reach fresh audiences in the international marketplace."
          )}
        </span>
        <br />
        <span className={Style.BlueSectionBody2Title}>
          {t("That’s where we come in.")}
        </span>
        <br />
        <Button
          style={{
            margin: "3% 0 5%",
            padding: MyWidth > 480 ? "11px 36px 11px 36px" : "3px 8px 3px 8px",
            fontWeight: "500",
            fontSize: MyWidth > 480 ? "1.125rem" : "0.8rem",
            borderRadius: MyWidth > 480 ? "16px" : "10px",
            minHeight: MyWidth > 480 ? "44px" : "30px",
          }}
          onClick={() => {
            history.push("/about-us");
          }}
        >
          {" "}
          {t("Learn More About iMachinary")}{" "}
        </Button>
      </div>
    </div>
  );
};
