import React from "react";
import Style from "./WorkWithUs.module.css";
import { Button } from "../../Components/Button/index";
import { Container } from "../../Components/Container/Container";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import ViewportContext from "../../Shared/ViewportContext";
import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import "./styles.css";
import ReactGA from 'react-ga4';

export const WorkWithUs = () => {
  const MyWidth = useContext(ViewportContext).myWidth;
  const { t, i18n } = useTranslation();
  const history = useHistory();

  // const [isDoubleLineLang, setIsDoubleLineLang] = useState(false);

  // useEffect(() => {
  //   if (
  //     ["fr", "es_ES", "pt", "pt_PT", "de", "nl", "it", "pl", "ru", "sv"].includes(i18n.language)
  //   ) {
  //     setIsDoubleLineLang(true);
  //   } else {
  //     setIsDoubleLineLang(false);
  //   }
  // }, [i18n.language]);


  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/workwithus"});
  }, [])

  return (
    <>
      <Container style={{ backgroundColor: "#f5f5f7" }}>
        <div className={Style.innerContainer}>
          <span className={Style.Label1}>{`${t("Home").toUpperCase()}/${t(
            "Work with us"
          ).toUpperCase()}`}</span>
          <span
            className={Style.Label2}
            dangerouslySetInnerHTML={{
              __html: t(
                "We Are Barrier-Breakers, Bridge-Builders And Culturally Diverse Collaborators For The Global Age."
              ),
            }}
          ></span>
          <div className={Style.ButtonAndParagContainer}>
            <span className={Style.WeAreAlways} dangerouslySetInnerHTML={{
              __html: t(
                "If you believe in the value of human connection, blended in with the sophistication of modern technology, then we want to work with YOU."
              ),
            }}>
            </span>
            <div className={Style.buttonContainer}>
              <Button
                onClick={() => {
                  history.push("/work-with-us/new");
                }}
                color={"primary"}
                style={{
                  padding:
                    MyWidth > 480 ? "11px 36px 11px 36px" : "3px 8px 3px 8px",
                  fontWeight: "500",
                  fontSize: MyWidth > 480 ? "1.125rem" : "0.8rem",
                  borderRadius: MyWidth > 480 ? "16px" : "10px",
                  minHeight: MyWidth > 480 ? "44px" : "30px",
                  minWidth: MyWidth > 480 ? "200px" : "100px"
                }}
              >
                {t("Join Us")}
              </Button>
            </div>
          </div>

          {/* <span class="OpenPositions">{t("Open positions")}</span> */}
          <div className={Style.WhiteBox}>
            <span className={Style.UpsActually}>
              {t("Ups! Actually there are not any open positions available")}
            </span>
            <span className={Style.WeWillPost}>
              {t("We will post them here as soon as possible!")}
            </span>
            <span className={Style.AnywayIfYou}>
              {t(
                "Anyway if you want to join us, you can send us your CV and we will contact you to any open roles wich it fits. Good luck with your search!"
              )}
            </span>
          </div>
        </div>
      </Container>
    </>
  );
};
