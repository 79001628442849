import React, { useState, useContext, useEffect } from "react";
import Style from "./WorkWithUsForm.module.css";
import { Button } from "../../Components/Button/index";
import { Container } from "../../Components/Container/Container";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { Input } from "../../Components/Input";
import { Checkbox } from "../../Components/Checkbox/Checkbox";
import { Select } from "../../Components/Select/index";
import { TextArea } from "../../Components/TextArea/index";
import { useHistory } from "react-router";
import axios from "axios";
import { Spinner } from "../../Components/Spinner/Spinner";
import { ThankYou } from "../../Components/ThankYou/ThankYou";
import ViewportContext from "../../Shared/ViewportContext";
import ReactGA from 'react-ga4';

export const WorkWithUsForm = () => {
  const MyWidth = useContext(ViewportContext).myWidth;
  const history = useHistory();
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  const workWithUs = t("Work with us");

  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .post("/api/work-with-us", data)
      .then(() => {
        setShowThankYou(true);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  };
  
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/service/workwithusForm" });
  }, [])

  const roles = ["Translator", "DTP", "Project Manager", "Designer", "Others"];

  return (
    <>
      <Container style={{ backgroundColor: "#f5f5f7" }}>
        <div className={Style.innerContainer}>
          {!showThankYou ? (
            <>
              <span className={Style.Label1}>{`${t(
                "Home"
              ).toUpperCase()}/${workWithUs.toUpperCase()}`}</span>
              <span className={Style.Label2}>{workWithUs}</span>
              {!isLoading ? (
                <>
                  <div className={Style.ControllerContainer}>
                    <Controller
                      name='fullname'
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholderText={t("Full name") + "*"}
                          error={errors[field.name]?.message}
                        />
                      )}
                      rules={{ required: t("This field is required") }}
                    />
                    <Controller
                      name='email'
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholderText={t("Email") + "*"}
                          error={errors[field.name]?.message}
                        />
                      )}
                      rules={{
                        required: t("This field is required"),
                        validate: {
                          isEmail: (v) => {
                            if (
                              !String(v)
                                .toLowerCase()
                                .trim()
                                .match(
                                  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i
                                )
                            ) {
                              return "The email is not valid";
                            }

                            return true;
                          },
                        },
                      }}
                    />
                    <Controller
                      name='role'
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholderText={t("Role") + "*"}
                          options={roles.map((v) => ({
                            value: v,
                            label: t(v),
                          }))}
                          style={{ margin: 0 }}
                          error={errors[field.name]?.message}
                        />
                      )}
                      rules={{ required: t("This field is required") }}
                    />
                    <Controller
                      name='phoneNumber'
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholderText={t("Phone Number") + "*"}
                          error={errors[field.name]?.message}
                        />
                      )}
                      rules={{ required: t("This field is required") }}
                    />
                    <Controller
                      name='linkedin'
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholderText={t("Linkedin")}
                          error={errors[field.name]?.message}
                        />
                      )}
                    />

                    <Controller
                      name='message'
                      control={control}
                      render={({ field }) => (
                        <TextArea
                          {...field}
                          placeholderText={t("Tell us about you") + "*"}
                          error={errors[field.name]?.message}
                          width='600px'
                        />
                      )}
                      rules={{ required: t("This field is required") }}
                    />
                    {/* <Controller
                      name='terms'
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value ? true : false}
                          error={errors[field.name]?.message}
                          label={t("Agree terms and privacy")}
                        />
                      )}
                      rules={{ required: t("You must accept the terms") }}
                    /> */}
                  </div>
                  <div className={Style.formButtons}>
                    <Button
                      variant="ghost"
                      color="secondary"
                      style={{
                        padding:
                          MyWidth > 480
                            ? "11px 36px 11px 36px"
                            : "3px 8px 3px 8px",
                        fontSize: MyWidth > 480 ? "1.125rem" : "0.8rem",
                        borderRadius: MyWidth > 480 ? "16px" : "10px",
                        minHeight: MyWidth > 480 ? "44px" : "30px",
                        minWidth: 100,
                      }}
                      onClick={() => {
                        history.push("/work-with-us");
                      }}
                    >
                      {t("Go back")}
                    </Button>
                    <Button
                      htmlType='submit'
                      style={{
                        padding:
                          MyWidth > 480
                            ? "11px 36px 11px 36px"
                            : "3px 8px 3px 8px",
                        fontSize: MyWidth > 480 ? "1.125rem" : "0.8rem",
                        borderRadius: MyWidth > 480 ? "16px" : "10px",
                        minHeight: MyWidth > 480 ? "44px" : "30px",
                        minWidth: 100,
                      }}
                      onClick={handleSubmit(onSubmit)}
                    >
                      {t("Submit")}
                    </Button>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <>
                    <h1>Processing your request</h1>
                    <Spinner />
                  </>
                </div>
              )}{" "}
            </>
          ) : (
            <ThankYou />
          )}
        </div>
      </Container>
    </>
  );
};
