import Style from "./ServiceLayout.module.css";
import { Button } from "../Button";
import { Container } from "../Container/Container";
import { useContext } from "react";
import ViewportContext from "../../Shared/ViewportContext";
import { useTranslation } from "react-i18next";

const ServiceLayout = (props) => {
  const { t } = useTranslation();
  const MyWidth = useContext(ViewportContext).myWidth;
  const { title, subtitle, handleClick, buttonText } = props;
  return (
    <>
      <Container style={{ backgroundColor: "#f5f5f7", flex: 1 }}>
        <div className={Style.innerContainer}>
          {MyWidth > 480 && <span className={Style.Label1}>{title}</span>}
          <span className={Style.Label2}>{subtitle}</span>
          {props.children}
          <div className={Style.ButtonGetItContainer}>
            <Button
              onClick={handleClick}
              color={"primary"}
              style={
                MyWidth > 480
                  ? { fontWeight: "normal" }
                  : {
                      fontWeight: "normal",
                      width: "auto",
                      minWidth: "114px",
                      height: "auto",
                      padding: "10px 9px",
                      minHeight: '30px'
                    }
              }
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
};

export { ServiceLayout };
