import { FaLongArrowAltRight, FaLongArrowAltLeft } from "react-icons/fa";
import styles from "./Button.module.scss";
import { useTranslation } from "react-i18next";

const Button = ({ htmlType, variant, color, children, onClick, style }) => {
  const { i18n } = useTranslation();
  
  return (
    <>
    {
      i18n.dir() === "ltr"  ? (
        <button
          className={`${styles.btn} ${styles[`btn-${variant}`]} ${
            styles[`btn-${color}`]
          }`}
          onClick={onClick}
          style={style}
          type={htmlType}
        >
          {
            <span
              className={styles.text}
              style={{
                fontSize: style?.fontSize,
                fontWeight: style?.fontWeight,
                color: style?.color,
              }}
              >
                {children}
            </span>
          }
          {
            variant === "arrow" && (
              <>
                <svg width="0" height="0">
                  <linearGradient id="gradient" x1="100%" y1="100%" x2="0%" y2="0%">
                    <stop stopColor="#787ff6" offset="0%" />
                    <stop stopColor="#4adede" offset="100%" />
                  </linearGradient>
                </svg>
                <FaLongArrowAltRight
                  style={{
                    fill: color === "default" ? "url(#gradient)" : "var(--babyblue)",
                    marginLeft: ".715rem",
                    marginTop: ".3rem",
                  }}
                  size={color === "default" ? "1.5rem" : "1.275rem"}
                />
              </>
            ) 
          } 
          </button>
      ) : (

        <button
        className={`${styles.btn} ${styles[`btn-${variant}`]} ${
          styles[`btn-${color}`]
        }`}
        onClick={onClick}
        style={style}
        type={htmlType}
      >
        {
          variant === "arrow" && (
            <>
              <svg width="0" height="0">
                <linearGradient id="gradient" x1="100%" y1="100%" x2="0%" y2="0%">
                  <stop stopColor="#787ff6" offset="0%" />
                  <stop stopColor="#4adede" offset="100%" />
                </linearGradient>
              </svg>
              <FaLongArrowAltLeft
                style={{
                  fill: color === "default" ? "url(#gradient)" : "var(--babyblue)",
                  marginRight: ".715rem",
                  marginTop: ".3rem",
                }}
                size={color === "default" ? "1.5rem" : "1.275rem"}
              />
          </>
          ) 
        } 
        {
          <span
            className={styles.text}
            style={{
              fontSize: style?.fontSize,
              fontWeight: style?.fontWeight,
              color: style?.color,
            }}
            >
              {children}
          </span>
        }
        
        </button>
      )
    }
  </>
  )
}


Button.defaultProps = {
  variant: "contained",
  color: "default",
  onClick: () => false,
  style: null,
  htmlType: "button",
};

export { Button };
