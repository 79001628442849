import React, { useState, useEffect } from "react";
import styles from "./Input.module.scss";

function Input({ value, placeholderText, onChange, width, type, error }) {
  const [selectType, setSelectType] = useState(type);
  const [alertColor, setAlertColor] = useState("");

  useEffect(() => {
    if (
      type !== "text" &&
      type !== "date" &&
      type !== "password" &&
      type !== "number"
    ) {
      setSelectType("text");
    }
  }, [type]);
  useEffect(() => {
    if (error !== undefined) {
      setAlertColor("#ff7b73");
    } else {
      setAlertColor("");
    }
  }, [error]);

  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <div className={styles.container} style={{ width: width }}>
      {type !== "date" && (
        <>
          <input
            value={value}
            placeholder={placeholderText}
            autoComplete="off"
            className={styles.input}
            onChange={handleChange}
            type={selectType}
            style={{ border: `solid 1px`, borderColor: `${alertColor}` }}
          />
          <p className={styles.alertMessage}>{error}</p>
        </>
      )}
    </div>
  );
}

Input.defaultProps = {
  placeholderText: "placeholder",
  width: "384px",
  type: "text",
};

export { Input };
