import React from "react";
import Style from "./Container.module.css";

const Container = (props) => {
  return (
    <div className={Style.Container} style={props.style}>
      {props.children}
    </div>
  );
};

export { Container };
