import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaGlobeAmericas } from "react-icons/fa";
import { ReactComponent as UpArrow } from "./img/atom-icon-field-arrow-header-up.svg";
import { ReactComponent as DownArrow } from "./img/atom-icon-field-arrow-header-down.svg";

import styles from "./LanguageSelector.module.scss";
import { NavBarButton } from "../NavBarButton";
import NavBarContext from "../../Shared/NavBarContext";

const LanguageSelector = (props) => {
  const { MyWidth, OpenedFromMobile, setIsLangMenuOpen } = props;
  const { t, i18n } = useTranslation();
  const languageSelectorRef = useRef();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { theme } = useContext(NavBarContext);

  const onChangeLanguage = (lang) => {
    setIsLangMenuOpen(false);
    setIsDropdownOpen(false);
    i18n.changeLanguage(lang);
    document.documentElement.setAttribute("dir", i18n.dir());
  };
  const _onClickOutside = (e) => {
    if (
      languageSelectorRef.current &&
      !languageSelectorRef.current.contains(e.target)
    ) {
      setIsDropdownOpen(false);
    }
  };

  const langList = {
    en: "English",
    es: "Español (Latam)",
    pt: "Português (Brazil)",
    de: "Deutsch",
    nl: "Nederlands",
    it: "Italiano",
    fr: "Français",
    ja: "日本語",
    ko: "한국어",
    pl: "Polski",
    ru: "Русский",
    sv: "Svenska",
    zh: "繁體中文",
    en_GB: "English (UK)",
    es_ES: "Español (España)",
    pt_PT: "Português (Portugal)",
    ar: "عرب",
    he: "עִברִית",
    id: 'Bahasa Indonesia'
  };

  useEffect(() => {
    let listener;
    if (isDropdownOpen) {
      if (!listener) {
        listener = window.addEventListener("click", _onClickOutside);
      }
    } else {
      if (listener) {
        listener = window.removeEventListener("click", _onClickOutside);
      }
    }

    return () => {
      if (listener) {
        window.removeEventListener("click", _onClickOutside);
        listener = null;
      }
    };
  }, [isDropdownOpen, languageSelectorRef]);

  return (
    <div
      className={`${styles.languageSelector} ${
        styles[`languageSelector-${theme}`]
      }`}
      ref={languageSelectorRef}
    >
      {MyWidth > 1024 && (
        <div
          className={styles.main}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <FaGlobeAmericas className={styles.icon} />

          <NavBarButton text={langList[i18n.language]} />
          <span>
            {isDropdownOpen ? (
              <UpArrow className={styles.arrow} />
            ) : (
              <DownArrow className={styles.arrow} />
            )}
          </span>
        </div>
      )}
      <div
        className={`${styles.dropdown} ${
          isDropdownOpen || OpenedFromMobile ? styles["dropdown-open"] : ""
        }`}
      >
        <div className={styles.langContainer}>
          <NavBarButton
            IsNotBold={i18n.language !== "en"}
            text={langList["en"]}
            onClick={() => onChangeLanguage("en")}
          />
        </div>
        <div className={styles.langContainer}>
          <NavBarButton
            IsNotBold={i18n.language !== "es"}
            text={langList["es"]}
            onClick={() => onChangeLanguage("es")}
          />
        </div>
        <div className={styles.langContainer}>
          <NavBarButton
            IsNotBold={i18n.language !== "pt"}
            text={langList["pt"]}
            onClick={() => onChangeLanguage("pt")}
          />
        </div>
        <div className={styles.langContainer}>
          <NavBarButton
            IsNotBold={i18n.language !== "de"}
            text={langList["de"]}
            onClick={() => onChangeLanguage("de")}
          />
        </div>
        <div className={styles.langContainer}>
          <NavBarButton
            IsNotBold={i18n.language !== "nl"}
            text={langList["nl"]}
            onClick={() => onChangeLanguage("nl")}
          />
        </div>
        <div className={styles.langContainer}>
          <NavBarButton
            IsNotBold={i18n.language !== "it"}
            text={langList["it"]}
            onClick={() => onChangeLanguage("it")}
          />
        </div>
        <div className={styles.langContainer}>
          <NavBarButton
            IsNotBold={i18n.language !== "fr"}
            text={langList["fr"]}
            onClick={() => onChangeLanguage("fr")}
          />
        </div>
        <div className={styles.langContainer}>
          <NavBarButton
            IsNotBold={i18n.language !== "ja"}
            text={langList["ja"]}
            onClick={() => onChangeLanguage("ja")}
          />
        </div>
        <div className={styles.langContainer}>
          <NavBarButton
            IsNotBold={i18n.language !== "ko"}
            text={langList["ko"]}
            onClick={() => onChangeLanguage("ko")}
          />
        </div>
        <div className={styles.langContainer}>
          <NavBarButton
            IsNotBold={i18n.language !== "pl"}
            text={langList["pl"]}
            onClick={() => onChangeLanguage("pl")}
          />
        </div>
        <div className={styles.langContainer}>
          <NavBarButton
            IsNotBold={i18n.language !== "ru"}
            text={langList["ru"]}
            onClick={() => onChangeLanguage("ru")}
          />
        </div>
        <div className={styles.langContainer}>
          <NavBarButton
            IsNotBold={i18n.language !== "sv"}
            text={langList["sv"]}
            onClick={() => onChangeLanguage("sv")}
          />
        </div>
        <div className={styles.langContainer}>
          <NavBarButton
            IsNotBold={i18n.language !== "zh"}
            text={langList["zh"]}
            onClick={() => onChangeLanguage("zh")}
          />
        </div>
        <div className={styles.langContainer}>
          <NavBarButton
            IsNotBold={i18n.language !== "en_GB"}
            text={langList["en_GB"]}
            onClick={() => onChangeLanguage("en_GB")}
          />
        </div>
        <div className={styles.langContainer}>
          <NavBarButton
            IsNotBold={i18n.language !== "es_ES"}
            text={langList["es_ES"]}
            onClick={() => onChangeLanguage("es_ES")}
          />
        </div>
        <div className={styles.langContainer}>
          <NavBarButton
            IsNotBold={i18n.language !== "pt_PT"}
            text={langList["pt_PT"]}
            onClick={() => onChangeLanguage("pt_PT")}
          />
        </div>
        <div className={styles.langContainer}>
          <NavBarButton
            IsNotBold={i18n.language !== "id"}
            text={langList["id"]}
            onClick={() => onChangeLanguage("id")}
          />
        </div>
        {/* <div className={styles.langContainer}>
          <NavBarButton
            IsNotBold={i18n.language !== "ar"}
            text={langList["ar"]}
            onClick={() => onChangeLanguage("ar")}
          />
        </div>
        <div className={styles.langContainer}>
          <NavBarButton
            IsNotBold={i18n.language !== "he"}
            text={langList["he"]}
            onClick={() => onChangeLanguage("he")}
          />
        </div> */}
      </div>
    </div>
  );
};

export { LanguageSelector };
