import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router";
import { Modal } from "../../Components/Modal/Modal";
import { ProcessStep3 } from "../../Components/ProcessStep3/ProcessStep3";
import { Spinner } from "../../Components/Spinner/Spinner";
import { Step1 } from "../../Components/Step1/Step1";
import { ThankYou } from "../../Components/ThankYou/ThankYou";
import ViewportContext from "../../Shared/ViewportContext";
import { FaExclamationTriangle } from "react-icons/fa";
import { Button } from "../../Components/Button";
import ReactGA from 'react-ga4';

const { ProcessStep2 } = require("../../Components/ProcessStep2/ProcessStep2");

const Process = () => {
  const MyHeight = useContext(ViewportContext).myHeight;
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const initialState = {
    services: [],
    industry: "",
    briefFile: "",
    message: "",
    company: "",
    fullname: "",
    email: "",
    terms: false,
  };

  const [state, setState] = useState({ ...initialState, ...location.state });
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const postData = (data) => {
    const formData = new FormData();

    Object.keys(data).map((k) => formData.append(k, data[k]));

    setIsLoading(true);

    axios
      .post("/api/projects", formData)
      .then(() => {
        history.push(`${path}/thank-you`);
      })
      .catch(function (error) {
        setShowErrorModal(true);
        console.log(error, state);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/process"});

    setIsLoading(true);

    setTimeout(() => setIsLoading(false), 5000);
  }, []);

  return (
    <Switch>
      <Route
        exact
        path={path}
        render={() => <Redirect to={`${path}/step1`} />}
      />
      <Route
        path={`${path}/step1`}
        render={() => (
          <Step1
            state={state}
            onSubmit={(data) => {
              setState({ ...state, ...data });
              history.push(`${path}/step2`);
            }}
          />
        )}
      />
      <Route
        path={`${path}/step2`}
        render={() => (
          <ProcessStep2
            state={state}
            setState={setState}
            onSubmit={(data) => {
              setState({ ...state, ...data });
              history.push(`${path}/step3`);
            }}
          />
        )}
      />
      <Route
        path={`${path}/step3`}
        render={() =>
          !isLoading ? (
            <>
              <ProcessStep3
                data={state}
                setData={setState}
                onSubmit={(data) => {
                  setState({ ...state, ...data });
                  postData({ ...state, ...data });
                }}
              />

              <Modal isOpen={showErrorModal} setIsOpen={setShowErrorModal}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FaExclamationTriangle
                    style={{
                      fill: "#ff7b73",
                    }}
                    size={"49px"}
                  />
                  <span
                    style={{
                      fontSize: "20px",
                      color: "#1f2f98",
                      fontWeight: "500",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                   {t('Message not delivered')} 
                  </span>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.38",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#1f2f98",
                      marginTop: "20px",
                      width: '283px',
                    }}
                  >
                   {t('There was a problem delivering your message. Please try again later.')} 
                  </span>
                  <Button
                    variant="ghost"
                    onClick={() => {
                      setShowErrorModal(false)
                    }}
                    style={{
                      background: "#47d1d1",
                      color: "white",
                      fontWeight: "500",
                      marginTop: '20px'
                    }}
                  >
                    {t("Ok")}
                  </Button>
                  
                </div>
              </Modal>
            </>
          ) : (
            <div
              style={{
                height: `${MyHeight - 120}px`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner />
            </div>
          )
        }
      />
      <Route
        path={`${path}/thank-you`}
        render={() => (
          <ThankYou
            Paragraph={
              <>
                {t("Your message has been successfully sent.")}
                <br />
                {t("We will contact you very soon.")}
              </>
            }
          />
        )}
      />
    </Switch>
  );
};

export { Process };
