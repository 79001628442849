import styles from "./Card.module.scss";

const Card = ({ variant, style, children }) => (
  <div
    className={variant ? `${styles.card} ${styles[`card-${variant}`]}` : ""}
    style={style}
  >
    {children}
  </div>
);

Card.defaultProps = {
  variant: "default",
};

export { Card };
