import React from "react";
import styles from "./NotFound404.module.css";
import image from "./404world.svg";
import image404 from "./404.svg";
import { Button } from "../../Components/Button";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useContext } from "react";
import ReactGA from 'react-ga4';
function NotFound404() {
  const { t } = useTranslation();
  const history = useHistory();

  const goHome = () => {
    history.push("/");
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/notfound"});
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.imageContainer}>
          <img src={image404} className={styles.image} />
        </div>
        <p className={styles.text}>
          {t("Oops! The page you are looking for doesn’t exist.")}
        </p>
      </div>

      <div
        className={styles.bottomContainer}
        style={{
          backgroundImage: `url(${image})`,
          backgroundPosition: "bottom center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Button
          style={{ background: "#47d1d1", margin: "7% auto" }}
          onClick={goHome}
        >
          {t("Go back")}
        </Button>
      </div>
    </div>
  );
}

export default NotFound404;
