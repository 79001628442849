import { useForm, Controller } from "react-hook-form";
import { Button } from "../../Components/Button";

import { Container } from "../../Components/Container/Container";
import { Input } from "../../Components/Input";
import { Select } from "../../Components/Select";
import { TextArea } from "../../Components/TextArea";
import { Typography } from "../../Components/Typography";
import { Checkbox } from "../../Components/Checkbox/Checkbox";
import { Spinner } from "../../Components/Spinner/Spinner";

import styles from "./ContactUs.module.scss";
import { ThankYou } from "../../Components/ThankYou/ThankYou";
import { useEffect, useState, useContext } from "react";
import ViewportContext from "../../Shared/ViewportContext";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { countries } from "../../Shared/countriesList";
import ReactGA from 'react-ga4';

const ContactUs = () => {
  const { t } = useTranslation();
  const [showThankYou, setShowThankYou] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [isWaitingFormReply, setIsWaitingFormReply] = useState(false);
  const MyWidth = useContext(ViewportContext).myWidth;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const history = useHistory();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/about-us"});
    const filteredList = countries.map((country) => t(country.name));
    setCountriesList(filteredList);
  }, []);



  const sendData = (data) => {
    axios
      .post("/api/contact-us", data)
      .then(() => {
        setIsWaitingFormReply(false);
        setShowThankYou(true);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsWaitingFormReply(false));
  };
  const onSubmit = async (data) => {
    await setIsWaitingFormReply(true);
    await sendData(data);
  };

  return (
    <Container
      style={{
        backgroundColor: "#f5f5f7",
        minHeight: "calc(100% - 55px)",
      }}
    >
      {isWaitingFormReply && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "800px",
          }}
        >
          <>
            <h1>Processing your request</h1>
            <Spinner />
          </>
        </div>
      )}
      {!showThankYou && !isWaitingFormReply
        ? countriesList.length > 0 && (
            <div className={styles.innerContainer}>
              <span className={styles.title}>
                {`${t("Home").toUpperCase()}/${t("Contact us").toUpperCase()}`}
              </span>
              <span
                className={styles.subtitle}
                dangerouslySetInnerHTML={{
                  __html: t("Real Human Beings"),
                }}
              ></span>
              <span className={styles.ConnectWith}>
                {t("Connect with our 24/7 customer support staff today.")}
              </span>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="fullname"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholderText={t("Full name") + "*"}
                      error={errors[field.name]?.message}
                    />
                  )}
                  rules={{ required: t("This field is required") }}
                />
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholderText={t("Email") + "*"}
                      type="email"
                      error={errors[field.name]?.message}
                    />
                  )}
                  rules={{
                    required: t("This field is required"),
                    validate: {
                      isEmail: (v) => {
                        if (
                          !String(v)
                            .toLowerCase()
                            .trim()
                            .match(
                              /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i
                            )
                        ) {
                          return "The email is not valid";
                        }

                        return true;
                      },
                    },
                  }}
                />
                <Controller
                  name="company"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholderText={t("Company") + "*"}
                      error={errors[field.name]?.message}
                    />
                  )}
                  rules={{ required: t("This field is required") }}
                />
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      width="384px"
                      placeholderText={t("Country")}
                      options={countriesList.map((v) => ({
                        value: v,
                        label: t(v),
                      }))}
                    />
                  )}
                />
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      width="588px"
                      error={errors[field.name]?.message}
                      placeholderText={t("Write some lines for us") + "*"}
                    />
                  )}
                  rules={{ required: t("This field is required") }}
                />
                {/* <div className={styles.terms}>
                  <Controller
                    name='terms'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        error={errors[field.name]?.message}
                        label={t("Agree terms and privacy")}
                      />
                    )}
                    rules={{ required: t("You must accept the terms") }}
                  />
                </div> */}
                <div className={styles.formButtons}>
                  <Button
                    variant="ghost"
                    color="secondary"
                    style={{
                      padding:
                        MyWidth > 480
                          ? "11px 36px 11px 36px"
                          : "3px 8px 3px 8px",
                      fontSize: MyWidth > 480 ? "1.125rem" : "0.8rem",
                      borderRadius: MyWidth > 480 ? "16px" : "10px",
                      minHeight: MyWidth > 480 ? "44px" : "30px",
                      minWidth: 100,
                    }}
                    onClick={() => history.goBack()}
                  >
                    {t("Go back")}
                  </Button>
                  <Button
                    htmlType="submit"
                    style={{
                      padding:
                        MyWidth > 480
                          ? "11px 36px 11px 36px"
                          : "3px 8px 3px 8px",
                      fontSize: MyWidth > 480 ? "1.125rem" : "0.8rem",
                      borderRadius: MyWidth > 480 ? "16px" : "10px",
                      minHeight: MyWidth > 480 ? "44px" : "30px",
                      minWidth: 100,
                    }}
                  >
                    {t("Submit")}
                  </Button>
                </div>
              </form>
            </div>
          )
        : !isWaitingFormReply && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <ThankYou
                style={{ flex: 1 }}
                message={t(
                  "Your message has been successfully sent. We will contact you very soon."
                )}
              />
            </div>
          )}
    </Container>
  );
};

export { ContactUs };
