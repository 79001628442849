import Slider from "react-slick";
import { IconButton } from "../IconButton";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.css";
import { useEffect, useState } from "react";

const Carousel = (props) => {
  const PrevArrow = (props) => (
    <IconButton
      {...props}
      icon={FaChevronLeft}
      style={{
        minWidth: "32px",
        minHeight: "32px",
        width: "1.5rem",
        height: "1.5rem",
      }}
    />
  );
  const NextArrow = (props) => (
    <IconButton
      {...props}
      icon={FaChevronRight}
      style={{
        minWidth: "32px",
        minHeight: "32px",
        width: "1.5rem",
        height: "1.5rem",
      }}
    />
  );
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 890,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };
  return (
    <div className='SliderMainBody'>
      <Slider {...settings}>{props.children}</Slider>
    </div>
  );
};

export { Carousel };
