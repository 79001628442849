import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { Container } from "../Container/Container";
import { Modal } from "../Modal/Modal";
import { Select } from "../Select";
import { TextArea } from "../TextArea";
import { Typography } from "../Typography";
import { ServiceModalEntry } from "../ServiceModalEntry/ServiceModalEntry";
import { FaRegTimesCircle, FaTimes } from "react-icons/fa";
import PlusIcon from "./madia/atom_icon.svg";
import { GoPrimitiveDot } from "react-icons/go";
import styles from "./ProcessStep2.module.scss";
import { IconButton } from "../IconButton";
import { Card } from "../Card";
import { useState } from "react";
import { useHistory } from "react-router";
import { InputFile } from "../InputFile/InputFile";
import { useContext } from "react";
import ViewportContext from "../../Shared/ViewportContext";

const ProcessStep2 = ({ state, setState, onSubmit }) => {
  const MyWidth = useContext(ViewportContext).myWidth;
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: { ...state, briefFile: "" },
  });
  const history = useHistory();

  const [addServiceModalOpen, setAddServiceModalOpen] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isMissingFileModalOpen, setIsMissingFileModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState();
  const [servicesState, setServicesState] = useState(state.services || []);
  const Services = {
    Internationalization: {
      route: "/services/internationalization",
      detail:
        "Our Internationalization services involve designing software so that it is easily adapted to several languages and states without any back-end engineering changes.",
    },
    Engineering: {
      route: "/services/engineering",
      detail:
        "Our professional Engineers are always there to assist you and help you choose the right tools for your localization needs to reach your international target audiences.",
    },
    Consulting: {
      route: "/services/consulting",
      detail:
        "We offer and design Consulting services to the organizations that are interested in investing in a localization strategy that drives beyond the project-specific method.",
    },
    Localization: {
      route: "/services/localization",
      detail:
        "Our exceptional Localization services can deliver projects seamlessly. We plan a proper localization strategy for you.",
    },
    "Desktop Publishing": {
      route: "/services/dtp",
      detail:
        "With our Desktop publishing service, we are able to recreate and format the layout or page design of any material in another language...",
    },
    Copywriting: {
      route: "/services/copywriting",
      detail:
        "Copywriting is a procedure of writing text for anything from billboards, brochures and websites to emails, blogs and advertisements to increase brand awareness...",
    },
  };

  const industries = [
    "Entertainment",
    "Education",
    "Legal",
    "Finance",
    "e-Commerce",
    "Healthcare",
  ];

  const handleNext = () => {
    const values = getValues();
    if (!values.briefFile) {
      setIsMissingFileModalOpen(!isMissingFileModalOpen);
    } else {
      onSubmit(values);
    }
  };

  const onServiceDelete = (service) => {
    setState({
      ...state,
      services: state.services.filter((svc) => service !== svc),
    });
    setServicesState(servicesState.filter((svc) => service !== svc));
  };

  const onServiceModify = (service) => {
    if (servicesState?.includes(service)) {
      setServicesState(servicesState.filter((svc) => service !== svc));
    } else {
      setServicesState([service, ...servicesState]);
    }
  };

  const onServiceModalOk = () => {
    setState({ ...state, services: servicesState });
  };

  return (
    <>
      <Container style={{ backgroundColor: "#f5f5f7" }}>
        <div className={styles.innerContainer}>
          <Typography
            variant="o"
            style={{
              marginBottom: "33px",
              color: "#1f2f98",
            }}
          >{`${t("Home").toUpperCase()}/${t("Start").toUpperCase()}/${t(
            "Your project"
          ).toUpperCase()}`}</Typography>
          <Typography
            variant="h2"
            style={{ color: "#1f2f98", marginBottom: "55px" }}
          >
            {t("Your project")}
          </Typography>
          <div className={styles.formContainer}>
            <form className={styles.form}>
              <Controller
                name="industry"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholderText={t("Industry")}
                    options={industries.map((v) => ({ value: v, label: t(v) }))}
                    style={{ margin: 0, marginBottom: "44px" }}
                    width={MyWidth > 480 ? 384 : MyWidth - 40}
                    error={errors[field.name]?.message}
                  />
                )}
              />
              <Controller
                name="projectDescription"
                control={control}
                render={({ field }) => (
                  <TextArea
                    {...field}
                    width={588}
                    placeholderText={t("Tell us about your project")}
                    error={errors[field.name]?.message}
                  />
                )}
              />
              <Controller
                name="briefFile"
                control={control}
                render={({ field }) => (
                  <InputFile
                    {...field}
                    label={t("Add brief")}
                    onChange={(e) => {
                      field.onChange(
                        e.target.files.length ? e.target.files[0] : e
                      );
                    }}
                    error={errors[field?.name]?.message}
                  />
                )}
              />
            </form>
            <div className={styles.services}>
              {state?.services?.map((service) => (
                <Card
                  style={{
                    boxSizing: "border-box",
                    display: "flex",
                    alignItems: "center",
                    height: MyWidth > 1330 ? "44px" : "30px",
                    width: "100%",
                    minWidth: MyWidth > 1330 ? "282px" : "180px",
                    padding: MyWidth > 1330 ? "6px 9px" : "4px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {MyWidth > 1330 && (
                      <GoPrimitiveDot
                        style={{ fill: "#787ff6" }}
                        size=".5rem"
                      />
                    )}
                    <Typography
                      variant="b4"
                      style={{
                        color: "#1f2f98",
                        marginLeft: "12px",
                        fontSize: MyWidth > 1050 ? "1rem" : "0.9rem",
                      }}
                    >
                      {t(service)}
                    </Typography>
                    <IconButton
                      style={{ marginLeft: "auto" }}
                      variant="ghost"
                      size="small"
                      icon={FaRegTimesCircle}
                      onClick={() => onServiceDelete(service)}
                    />
                  </div>
                </Card>
              ))}
              <div className={styles.addService}>
                <img
                  src={PlusIcon}
                  className={styles.plusButton}
                  onClick={() => setAddServiceModalOpen(!addServiceModalOpen)}
                />
                <Typography
                  variant="b4"
                  style={{
                    marginLeft: "1rem",
                    fontSize: MyWidth > 1050 ? "1rem" : "0.9rem",
                  }}
                >
                  {t("Add another service")}
                </Typography>
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              style={{ minWidth: "140px" }}
              color="secondary"
              onClick={() => {
                setState({ ...state, services: servicesState });
                history.goBack();
              }}
            >
              {t("Go back")}
            </Button>
            <Button
              style={{ minWidth: "140px" }}
              color="primary"
              onClick={handleNext}
            >
              {t("Next")}
            </Button>
          </div>
        </div>
      </Container>
      <Modal isOpen={addServiceModalOpen} setIsOpen={setAddServiceModalOpen}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="s2" style={{ color: "#1f2f98" }}>
            {t("Choose")}
          </Typography>
          <Typography
            variant="b4"
            style={{ marginBottom: "2rem", marginTop: "1rem" }}
          >
            {t("Select the services you need for your project")}
          </Typography>
          {Object.keys(Services).map((n) => (
            <ServiceModalEntry
              title={t(n)}
              onChange={() => onServiceModify(n)}
              onClick={() => {
                setSelectedService(n);
                setIsDetailModalOpen(true);
              }}
              selected={servicesState?.includes(n)}
            />
          ))}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "36px",
            }}
          >
            <Button
              style={{ minWidth: "140px" }}
              color="secondary"
              onClick={() => {
                setAddServiceModalOpen(!addServiceModalOpen);
                setServicesState(state.services);
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
             style={{ minWidth: "140px" }}
              color="primary"
              onClick={() => {
                setAddServiceModalOpen(!addServiceModalOpen);
                onServiceModalOk();
              }}
            >
              {t("Ok")}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={isDetailModalOpen} setIsOpen={setIsDetailModalOpen}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IconButton
            size="small"
            variant="ghost"
            icon={FaTimes}
            style={{ alignSelf: "flex-end", marginBottom: "10px" }}
            onClick={() => setIsDetailModalOpen(!isDetailModalOpen)}
          />
          <Typography
            variant="b3"
            style={{ color: "#1f2f98", maxWidth: "324px", textAlign: "center" }}
          >
            {t(Services[selectedService]?.detail)}
          </Typography>
          <Button
            variant="arrow"
            onClick={() => history.push(Services[selectedService]?.route)}
            style={{ marginTop: "25px" }}
          >
            {t("Read more")}
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={isMissingFileModalOpen}
        setIsOpen={setIsMissingFileModalOpen}
      >
        <div style={{ minWidth: "250px", width: "100%", textAlign: "center" }}>
          <Typography
            variant="s1"
            style={{
              color: "#1f2f98",
              marginBottom: "20px",
              fontSize: MyWidth > 500 ? "2.15rem" : "1.2rem",
              fontWeight: MyWidth > 500 ? "500" : "600",
            }}
          >
            {t("Attachment reminder")}
          </Typography>
          <Typography
            variant="b3"
            style={{
              color: "#1f2f98",
              fontSize: MyWidth > 500 ? "1.145rem" : "0.95rem",
            }}
          >
            {t("You may have forgotten to attach a file to this message.")}
          </Typography>
          <br />
          <Typography
            variant="b3"
            style={{
              color: "#1f2f98",
              fontSize: MyWidth > 500 ? "1.145rem" : "0.95rem",
            }}
          >
            {t("Would you like to continue anyway?")}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "27px",
            }}
          >
            <Button
              color="secondary"
              style={{ minWidth: "140px" }}
              onClick={() => setIsMissingFileModalOpen(!isMissingFileModalOpen)}
            >
              {t("Cancel")}
            </Button>
            <Button
              color="primary"
              style={{ minWidth: "140px" }}
              onClick={handleSubmit(onSubmit)}
            >
              {t("Ok")}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export { ProcessStep2 };
