import React from "react";
import Styles from "./ServiceModalEntry.module.css";

import { FaQuestionCircle } from "react-icons/fa";

import { IconButton } from "../IconButton";
import { Checkbox } from "../Checkbox/Checkbox";

const ServiceModalEntry = (props) => {
  let { selected, onChange, onClick, title } = props;
  console.log(selected);
  return (
    <div className={Styles.Container}>
      <Checkbox
        onChange={onChange}
        variant="small"
        checked={selected ? true : false}
        label={title}
        labelClassName={`${Styles.text} ${
          selected ? Styles.textSelected : Styles.textDeselected
        }`}
      />

      <IconButton onClick={onClick} icon={FaQuestionCircle} variant={"ghost"} />
    </div>
  );
};

export { ServiceModalEntry };
