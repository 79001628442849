import React from "react";
import Modal from "react-modal";
import { useContext } from "react";
import ViewportContext from "../../Shared/ViewportContext";



Modal.setAppElement("#root");

const ModalComponent = (props) => {
  const MyWidth = useContext(ViewportContext).myWidth;
  let { isOpen, setIsOpen } = props;

  const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: MyWidth > 384 ? "40px" : '15px',
    transform: "translate(-50%, -50%)",
    borderRadius: "9px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.15)",
    backgroundColor: "#fff",
    width: MyWidth > 384 ? 'auto' : MyWidth - 50
  },
  overlay: { zIndex: 1000, backgroundColor: "rgba(31, 47, 152, 0.2)" },
};

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      {props.children}
    </Modal>
  );
};

export { ModalComponent as Modal };
